import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import { productSizeChange } from "../../redux/apis/productAPI";

const VariantsSizeSelect = (props) => {
  const [selected, setSelected] = useState(props.init[0]);
  const dispatch = useDispatch();

  const handleChange = (value) => {
    setSelected(value);
    props.setVariants(props.name, value ? value.name : null);
    dispatch(productSizeChange(value));
  };

  useEffect(() => {
    dispatch(productSizeChange(selected));
  }, []);

  return (
    <div id={`v-${props.name.replace(/\s+/g, "-")}`}>
      <h3 className="text-sm font-medium capitalize">Size</h3>
      <div className="mt-2 rounded-md border border-gray-300">
        <Listbox value={selected} onChange={(value) => handleChange(value)}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left sm:text-sm">
              <span className="block truncate">
                {selected ? selected.name : "Select an option"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm border border-gray-300">
                {props.original.map((data, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active
                          ? "bg-indigo-100 text-indigo-900"
                          : "text-gray-900"
                      }`
                    }
                    value={data}
                    disabled={data.inStock == "0"}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          } ${data.inStock == "0" ? "disable" : ""}`}
                        >
                          {data.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
};

export default VariantsSizeSelect;
