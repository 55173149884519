import "./styles/global.css";
import { Provider as ReduxProvider } from "react-redux";
import reduxStore from "./redux/store";
import Routes from "./routing/Routes";

function App() {
  return (
    <ReduxProvider store={reduxStore}>
      <Routes />
    </ReduxProvider>
  );
}

export default App;
