import React, { useState } from "react";
import { cmykToHex, hexToCmyk } from "../../utils/product";

const VariantsColorSelect = (props) => {
  const [value, setValue] = useState(
    props.init[0].name ? cmykToHex(props.init[0].name) : "#ffffff"
  );
  const [noColor, setNoColor] = useState(props.init[0].name === "false");

  const handleChange = (value) => {
    setValue(value);
    props.setVariants(props.name, hexToCmyk(value));
  };

  const handleAddOrRemove = (handle) => {
    if (handle === "remove") {
      setNoColor(true);
      props.setVariants(props.name, "false");
    } else {
      setNoColor(false);
      setValue("#ffffff");
      props.setVariants(props.name, hexToCmyk("#ffffff"));
    }
  };

  return (
    <div id={`v-${props.name.replace(/\s+/g, "-")}`}>
      <h3 className="text-sm font-medium capitalize">{props.name}</h3>
      <div className="flex gap-2 items-center">
        {!noColor && (
          <input
            onChange={(e) => handleChange(e.target.value)}
            type="color"
            value={value}
            className="h-10 w-10 cursor-pointer bg-white"
          />
        )}
        <div
          className="cursor-pointer underline text-sm text-indigo-400 mt-2"
          onClick={() => handleAddOrRemove(noColor ? "add" : "remove")}
        >
          {noColor ? "Add" : "Remove"} Color
        </div>
      </div>
    </div>
  );
};

export default VariantsColorSelect;
