import React from "react";
import { isNull } from "../../utils/validation";

const PriceRangeTable = ({ data }) => {
  return (
    <div className="py-4 mt-6">
      {/* <p className="bold font-bold mb-4">Price Table</p> */}
      <div className="overflow-auto max-h-60">
        <table className="min-w-full divide-y divide-gray-200 border">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                (Size) Quantity
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Unit Cost
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((item, index) => (
              <tr key={index}>
                <td className="px-4 py-2 whitespace-nowrap">
                  {!isNull(item.variant_name) && `(${item.variant_name}) `}
                  {item.rangeMin === 0 ? 1 : item.rangeMin}+
                </td>
                <td className="px-4 py-2 whitespace-nowrap">
                  ${Number(item.cad).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PriceRangeTable;
