import React from "react";

const Gallery = () => {
  const importAll = (r) => {
    return r.keys().map(r);
  };

  const images = importAll(
    require.context("../images/gallery", false, /\.(jpg|jpeg|png)$/)
  );

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div key={index} className="overflow-hidden rounded-md">
            <img
              src={image}
              alt={`Gallery ${index + 1}`}
              className="w-full h-auto"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
