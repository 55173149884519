import React from "react";

const CategorySkeleton = () => {
  return (
    <div>
      <main className="mx-auto">
        <div className="hidden lg:block flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 skeleton">
            {/* Add skeleton class to simulate loading */}
            <div className="skeleton bg-gray-300 w-full h-6"></div>
          </h1>
        </div>
        <div className="lg:hidden lg:hidden flex flex-col items-center border-b border-gray-200 pb-6 pt-24">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 skeleton">
            <div className="skeleton bg-gray-300 w-full h-6"></div>
          </h1>
        </div>

        <section aria-labelledby="products-heading" className="pt-6">
          <h2 id="products-heading" className="sr-only">
            Products
          </h2>

          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
            {/* Filters */}
            <form className="hidden lg:block">
              <h3>Products</h3>
              <ul className="space-y-2 border-b border-gray-200 pb-6 text-gray-900 mt-4">
                <li>
                  <div className="skeleton bg-gray-300 w-full h-6"></div>
                </li>
                <li>
                  <div className="skeleton bg-gray-300 w-full h-6"></div>
                </li>
                <li>
                  <div className="skeleton bg-gray-300 w-full h-6"></div>
                </li>
                <li>
                  <div className="skeleton bg-gray-300 w-full h-6"></div>
                </li>
              </ul>
            </form>
            {/* MOBILE */}
            <form className="lg:hidden flex flex-col items-center">
              <div className="skeleton bg-gray-300 w-full h-6"></div>
            </form>

            {/* Product grid */}
            <div className="lg:col-span-3">
              {/* Simulate loading for the product list */}
              <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 gap5em">
                <div className="skeleton bg-gray-300 w-full h-24"></div>
                <div className="skeleton bg-gray-300 w-full h-24"></div>
                <div className="skeleton bg-gray-300 w-full h-24"></div>
                <div className="skeleton bg-gray-300 w-full h-24"></div>
                <div className="skeleton bg-gray-300 w-full h-24"></div>
                <div className="skeleton bg-gray-300 w-full h-24"></div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default CategorySkeleton;
