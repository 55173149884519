import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const QuantityButton = (props) => {
  const [quantity, setQuantity] = useState(props.qua);
  const refresh = useSelector((state) => state.pageReducer.refresh);

  useEffect(() => {
    setQuantity(props.qua);
  }, [refresh]);

  const increaseQuantity = () => {
    if ((props.max != 0 && quantity < props.max) || props.max == 0) {
      const q = Number(quantity) + props.unitSet;
      setQuantity(q);
      if (props.setW) {
        props.setQ(q, Number(props.weight) * q, props.idx, props.d_idx);
      } else {
        props.setQ(q, props.idx, props.d_idx);
      }
    }
  };

  const decreaseQuantity = () => {
    if (quantity > props.min) {
      const q = Number(quantity) - props.unitSet;
      setQuantity(q);
      if (props.setW) {
        props.setQ(q, Number(props.weight) * q, props.idx, props.d_idx);
      } else {
        props.setQ(q, props.idx, props.d_idx);
      }
    }
  };

  const handleInput = (e) => {
    setQuantity(e.target.value);
    props.setQ(e.target.value, props.idx, props.d_idx);
  };

  return (
    <div className="flex items-center bg-white rounded-md overflow-hidden border border-gray-300 w-full">
      <div
        onClick={decreaseQuantity}
        className="text-gray-600 px-3 py-2 cursor-pointer focus:outline-none"
      >
        -
      </div>
      <input
        type="number"
        disabled
        value={quantity}
        className="flex-1 text-center py-2 text-gray-800 w-full bg-white"
        onChange={handleInput}
      />
      <div
        onClick={increaseQuantity}
        className="text-gray-600 px-3 py-2 cursor-pointer focus:outline-none"
      >
        +
      </div>
    </div>
  );
};

export default QuantityButton;
