import {
  CART_CHANGE,
  ORDER_APPEND,
  ORDER_INIT,
  ORDER_LOADING,
  ORDER_UPDATESTATUS,
  SHIPPING_FEE,
} from "./types";

export const order_init = (data) => {
  return { type: ORDER_INIT, payload: data };
};

export const order_append = (data, page) => {
  return { type: ORDER_APPEND, payload: { data, page } };
};

export const order_updateStatus = (order_id, orderStatus) => {
  return { type: ORDER_UPDATESTATUS, payload: { order_id, orderStatus } };
};

export const shipping_fee = (data) => {
  return { type: SHIPPING_FEE, payload: data };
};

export const order_loading = (data) => {
  return { type: ORDER_LOADING, payload: data };
};

export const cart_change = (data) => {
  return { type: CART_CHANGE, payload: data };
};
