import React, { useState } from "react";
import { isValidInteger } from "../../utils/validation";
// import { isPositiveInteger } from "../../utils/validation";

const VariantsInput = (props) => {
  const [value, setValue] = useState(props.init[0].name ?? 0);

  const handleChange = (value) => {
    if (props.type === "number") {
      if (!isValidInteger(value)) {
        return;
      }
      if (props.name === "Tearoff" && value > 4) {
        return;
      }
    }
    setValue(value);
    props.setVariants(props.name, value);
    if (props.name === "Tearoff") {
      const tearoffContainer = document.getElementById("tearoff");
      if (value > 0) {
        tearoffContainer.classList.remove("hidden");
      } else {
        tearoffContainer.classList.add("hidden");
      }
    }
  };

  return (
    <div id={`v-${props.name.replace(/\s+/g, "-")}`}>
      <h3 className="text-sm font-medium capitalize">{props.name}</h3>
      <input
        id={`v-${props.name.replace(/\s+/g, "-")}-input`}
        onChange={(e) => handleChange(e.target.value)}
        type={props.type}
        min={0}
        value={value}
        max={props.name === "Tearoff" ? 4 : 999999}
        className="w-1/2 rounded-md border border-gray-200 px-2 py-2 mt-2 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
        onKeyDown={(evt) =>
          ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
        }
      />
    </div>
  );
};

export default VariantsInput;
