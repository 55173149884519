import {
  CATEGORIES_INIT,
  CATEGORIES_REFRESH,
  PRODUCT_SIZE_CHANGE,
} from "./types";

export const categories_init = (data) => {
  return { type: CATEGORIES_INIT, payload: data };
};

export const categories_refresh = (data) => {
  return { type: CATEGORIES_REFRESH, payload: data };
};

export const product_size_change = (data) => {
  return { type: PRODUCT_SIZE_CHANGE, payload: data };
};
