import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";

const VariantsRadioGroup = (props) => {
  const [selected, setSelected] = useState(props.init[0]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleChange = (value) => {
    setSelected(value);
    props.setVariants(props.name, value.name);
  };

  return (
    <div id={`v-${props.name.replace(/\s+/g, "-")}`}>
      <h3 className="text-sm font-medium capitalize">{props.name}</h3>

      <RadioGroup
        value={selected}
        onChange={(value) => handleChange(value)}
        className="mt-2"
      >
        <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
          {props.original.map((data) => (
            <RadioGroup.Option
              key={data.name}
              value={data}
              disabled={data.inStock == "0"}
              className={({ active }) =>
                classNames(
                  data.inStock == "1"
                    ? "cursor-pointer bg-white shadow-sm"
                    : "cursor-not-allowed bg-gray-50 text-gray-200",
                  active ? "ring-2 ring-indigo-500" : "",
                  "group relative flex items-center justify-center rounded-md border border-gray-300 py-3 px-4 text-sm hover:bg-gray-50 focus:outline-none sm:flex-1 "
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <RadioGroup.Label as="span">{data.name}</RadioGroup.Label>
                  {data.inStock == "1" ? (
                    <span
                      className={classNames(
                        active ? "border" : "border-2",
                        checked ? "border-indigo-500" : "border-transparent",
                        "pointer-events-none absolute -inset-px rounded-md"
                      )}
                      aria-hidden="true"
                    />
                  ) : (
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute -inset-px rounded-md"
                    >
                      <svg
                        className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                        stroke="currentColor"
                      >
                        <line
                          x1={0}
                          y1={100}
                          x2={100}
                          y2={0}
                          vectorEffect="non-scaling-stroke"
                        />
                      </svg>
                    </span>
                  )}
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default VariantsRadioGroup;
