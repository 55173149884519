import {
  API_PENDING,
  CART_ICON_REFRESH,
  CATALOG_SET,
  PAGE_REFRESH,
  SHIPPING_FEE_PENDING,
} from "./types";

export const category_set = (data) => {
  return { type: CATALOG_SET, payload: data };
};

export const page_refresh = () => {
  return { type: PAGE_REFRESH, payload: null };
};

export const shipping_fee_pending = (data) => {
  return { type: SHIPPING_FEE_PENDING, payload: data };
};

export const cartIcon_refresh = () => {
  return { type: CART_ICON_REFRESH, payload: null };
};

export const api_pending = (data) => {
  return { type: API_PENDING, payload: data };
};
