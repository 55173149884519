import React, { useEffect } from "react";
import Layout from "./Layout";
import banner1 from "../images/banner1.png";
import banner2 from "../images/banner2.png";
import banner3 from "../images/banner3.png";
import banner4 from "../images/banner4.png";
import generalPrinting from "../images/Running Count General Printing.pdf";
import ProductList from "../components/productList";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { categoriesInit } from "../redux/apis/productAPI";
import { useDispatch, useSelector } from "react-redux";
import { findProductArrIncategories } from "../utils/product";
import { useNavigate } from "react-router-dom";
import CarouselBanner from "../components/carousel-banner";

const Home = () => {
  const categories = useSelector((state) => state.productReducer.categories);
  // const refresh = useSelector((state) => state.productReducer.refresh);
  // const backgroundStyle = {
  //   backgroundImage: `url(${banner4})`,
  //   backgroundSize: "cover",
  // };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
    //init category
    dispatch(categoriesInit());
  };

  useEffect(() => {
    if (categories.length === 0) {
      fetchData();
    }
  }, []);

  return (
    <div className="homePage">
      <Layout>
        <div>
          {/* mx-auto max-w-2xl pb-14 */}
          <div>
            <CarouselBanner />
            <div className="text-center my-10">
              <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl py-4">
                Manufacturing race numbers globally since 1981
              </h1>
            </div>
          </div>

          {/* PC 3 blocks */}
          <div className="hidden lg:flex justify-center items-end gap-4 max-w-8xl mx-auto mb-20">
            {/* Rectangular Block 1 */}
            <div
              onClick={() => navigate("/products")}
              className="relative overflow-hidden rounded-md cursor-pointer"
            >
              <img
                className="w-full h-full object-cover"
                src={banner1}
                alt={banner1}
              />
              <div className="absolute inset-0 flex items-end justify-center text-white">
                <button className="text-white font-semibold pb-12">
                  Bib Numbers & Race Products
                </button>
              </div>
            </div>

            {/* Square Block 2 */}
            <div
              className="relative overflow-hidden rounded-md cursor-pointer"
              onClick={() => navigate("under-construction")}
            >
              <img
                className="w-full h-full object-cover"
                src={banner2}
                alt={banner2}
              />
              <div className="absolute inset-0 flex items-end justify-center bg-white-600 text-white">
                <button className="text-white font-semibold pb-12">
                  Track & Field Equipment
                </button>
              </div>
            </div>

            {/* Square Block 3 */}
            <div
              className="relative overflow-hidden rounded-md cursor-pointer"
              onClick={() => {
                window.open(generalPrinting, "_blank");
              }}
            >
              <img
                className="w-full h-full object-cover"
                src={banner3}
                alt={banner3}
              />
              <div className="absolute inset-0 flex items-end justify-center bg-white-600 text-white">
                <button className="text-white font-semibold pb-12">
                  General Printing
                </button>
              </div>
            </div>
          </div>

          {/* MOBILE 3 blocks */}
          <div className="sm:hidden px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-8xl mx-auto">
            {/* Rectangular Block 1 */}

            <div className="w-full sm:w-auto h-64 relative overflow-hidden rounded-md cursor-pointer">
              <div
                onClick={() => navigate("/products")}
                className="w-auto h-60 relative overflow-hidden rounded-md cursor-pointer"
              >
                <img
                  className="w-full h-full object-cover"
                  src={banner1}
                  alt={banner1}
                />
                <div className="absolute inset-0 flex items-end justify-center bg-white-600 text-white">
                  <button className="text-white font-semibold pb-12">
                    Bib Numbers & Race Products
                  </button>
                </div>
              </div>
            </div>

            {/* Square Block 2 */}
            <div
              className="w-full relative overflow-hidden rounded-md cursor-pointer"
              onClick={() => navigate("under-construction")}
            >
              <img
                className="w-full h-full object-cover"
                src={banner2}
                alt={banner2}
              />
              <div className="absolute inset-0 flex items-end justify-center bg-white-600 text-white">
                <button className="text-white font-semibold pb-12">
                  Track & Field Equipment
                </button>
              </div>
            </div>

            {/* Square Block 3 */}
            <div
              className="w-full relative overflow-hidden rounded-md cursor-pointer"
              onClick={() => {
                window.open(generalPrinting, "_blank");
              }}
            >
              <img
                className="w-full h-full object-cover"
                src={banner3}
                alt={banner3}
              />
              <div className="absolute inset-0 flex items-end justify-center bg-white-600 text-white">
                <button className="text-white font-semibold pb-12">
                  General Printing
                </button>
              </div>
            </div>
          </div>

          {/* <div className="relative py-24">
            <div
              className="mx-auto h-60 max-w-2xl lg:max-w-7xl bg-cover bg-bottom shadow-lg"
              style={backgroundStyle}
            >
            </div>
          </div> */}

          {/* <div className="bg-white mb-14">
            <div className="mx-auto max-w-2xl lg:max-w-7xl flex items-center justify-between mb-8">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">
                Featured Products
              </h2>
              <div
                onClick={() => navigate("/products")}
                className="flex items-center text-indigo-600 hover:underline cursor-pointer"
              >
                Shop All{" "}
                <ArrowRightIcon className="h-5 w-5 ml-1" aria-hidden="true" />
              </div>
            </div>

            <div className="mx-auto max-w-2xl lg:max-w-7xl">
              {!refresh ? (
                <ProductList
                  productArray={findProductArrIncategories(categories, 1).slice(
                    0,
                    4
                  )}
                />
              ) : (
                <div className="lg:col-span-3">
                  <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 lg:grid-cols-4 gap5em">
                    <div className="skeleton bg-gray-300 w-full h-24"></div>
                    <div className="skeleton bg-gray-300 w-full h-24"></div>
                    <div className="skeleton bg-gray-300 w-full h-24"></div>
                    <div className="skeleton bg-gray-300 w-full h-24"></div>
                  </div>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </Layout>
    </div>
  );
};

export default Home;
