import createAxios from "../../utils/createAxios";
import {
  categories_init,
  categories_refresh,
  product_size_change,
} from "../actions/productAction";
import { isNull } from "../../utils/validation";
import { sizeCheck } from "../../utils/product";

//get products
export const productGet = async (para_id) => {
  const searchParams = window.location.search;
  let id = searchParams.split("=")[1];
  if (isNull(id)) {
    id = para_id;
  }

  let res = await createAxios.get("/product", { params: { id } });
  console.log("product get", res.data);
  return res.data.data;
};

//get custom products
export const customProductGet = async (para_id) => {
  const searchParams = window.location.search;
  let id = searchParams.split("=")[1];
  if (isNull(id)) {
    id = para_id;
  }

  const res = await createAxios.get("/product", { params: { id } });
  console.log("customProductGet", res.data);
  const product = res.data.data;

  product["Upload Logo"] = [{ image: "", position: "", inStock: true }];
  product["Add Text"] = [
    {
      text: "",
      position: "",
      colour: "[0,0,0,100]",
      inStock: true,
    },
  ];
  return product;
};

export const categoriesInit = () => async (dispatch) => {
  try {
    dispatch(categories_refresh(true));
    const res = await createAxios.get("/allproducts");
    console.log("categoriesInit", res.data.data);
    const categories = res.data.data;

    dispatch(categories_init(categories));
    dispatch(categories_refresh(false));
  } catch (e) {
    console.error("init category error:", e);
  }
};

export const categoryGet = async (category_id) => {
  const res = await createAxios.get("/cateproducts", {
    params: { id: category_id },
  });
  console.log("category get", res.data);
  return res.data.data;
};

export const dependencyGet = async (productType) => {
  if (productType !== "dependency") {
    const res = await createAxios.get("/cateproducts", {
      params: { id: 3, productType: "dependency" },
    });
    let dependency = res.data.data;
    dependency = dependency.map((d) => ({
      ...d,
      quantity: 0,
      total: 0,
    }));
    return dependency;
  } else {
    return [];
  }
};

export const imageUpload = async (imageFile) => {
  try {
    const formData = new FormData();
    formData.append("file", imageFile);
    const res = await createAxios.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });
    return res.data.data.uri;
  } catch (e) {
    console.error("Image upload error:", e);
    return "";
  }
};

export const productSizeChange = (size) => (dispatch) => {
  const sizePercentage = sizeCheck(size.name);
  dispatch(product_size_change(sizePercentage));
};
