import React, { Fragment, useEffect, useRef, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

export default function TopNotificationBar(props) {
  const cancelButtonRef = useRef(null);

  return (
    <div className="topBar w-full">
      <div className="flex gap-4 bg-indigo-100 font-semibold p-8">
        <div>
          <InformationCircleIcon
            className="h-6 w-6 text-indigo-600"
            aria-hidden="true"
          />
        </div>
        <div>{props.msg}</div>
      </div>
    </div>
  );
}
