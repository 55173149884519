//PAGE
export const CATALOG_SET = "CATALOG_SET";
export const PAGE_REFRESH = "PAGE_REFRESH";
export const CART_ICON_REFRESH = "CART_ICON_REFRESH";
export const SHIPPING_FEE_PENDING = "SHIPPING_FEE_PENDING";
export const API_PENDING = "API_PENDING";

//PRODUCT
export const CATEGORIES_INIT = "CATEGORIES_INIT";
export const CATEGORIES_REFRESH = "CATEGORIES_REFRESH";
export const PRODUCT_SIZE_CHANGE = "PRODUCT_SIZE_CHANGE";

//ORDER
//dashboard
export const ORDER_INIT = "ORDER_INIT"; //current order in order detail
export const ORDER_APPEND = "ORDER_APPEND"; //append to order list
export const ORDER_UPDATESTATUS = "ORDER_UPDATESTATUS";

export const SHIPPING_FEE = "SHIPPING_FEE";
export const ORDER_LOADING = "ORDER_LOADING"; //loading when create an order
export const CART_CHANGE = "CART_CHANGE";
