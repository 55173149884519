import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import Layout from "./Layout";

export default function AboutUs() {
  return (
    <Layout>
      <div className="relative isolate overflow-hidden bg-white lg:overflow-visible lg:px-0">
        <div className="mx-auto gap-y-16 ">
          <div className="lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-indigo-600">
                  ABOUT US
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Running Count History
                </h1>
              </div>
            </div>
          </div>
          <div className="lg:mx-auto lg:w-full lg:px-8">
            <div className="lg:pr-4">
              <div className="text-base leading-7 text-gray-700">
                <p className="mt-8">
                  Running Count was started in 1981 by Dennis Fairall, the track
                  and field coach for the University of Windsor. After not
                  receiving his bib numbers in time for an event, Dennis started
                  to investigate more reliable options. He discovered that the
                  most reliable option was printing them himself. Dennis ran the
                  business until he stepped down prior to the 2005 Pan American
                  Games in Windsor. At that time, Darryl Currie, himself a
                  former Windsor Lancer track athlete, purchased Running Count.
                  Since then, Darryl has added many new products, including full
                  colour bibs, personalized bibs, adhesive bibs and a full line
                  of track and field equipment. Dennis’ goal was to create the
                  best quality product at the best price. That is still our goal
                  today.
                </p>
                <p className="mt-8">
                  Running Count is based in Windsor, Ontario, Canada, just
                  minutes from Detroit, Michigan. We have been able to take
                  advantage of one of the world's largest trade routes, allowing
                  us to ship bib numbers quickly and efficiently. We pride
                  ourselves on being able to print and deliver your bib numbers
                  in a timely fashion and our location helps us do just that.
                </p>

                <p className="mt-8">
                  Our bib numbers are printed using a high quality digital press
                  and are printed on Tyvek®, a waterproof and tearproof material
                  made from plastic fibers that are spun and then bonded
                  together by heat and pressure. This process is what gives
                  Tyvek® its strength and durability. Tyvek® bib numbers are
                  resistant to water and sweat, and will not tear if something
                  pulls on them at any time during competition. The durable
                  nature of Tyvek® makes our bib numbers applicable to a wide
                  variety of events, including marathons, track and field
                  competitions, mud runs, adventure races, team tryouts, skiing
                  and biking events, triathlons and many other uses.
                </p>
                <p className="mt-8">
                  We hope this gives you a better understanding of our high
                  quality product and our dedication to providing exceptional
                  customer service.
                </p>
                <p className="mt-8">Good luck with your event!</p>
                <p className="mt-8">Running Count Inc.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
