import React, { useEffect, useState, Fragment } from "react";
import { orderGet, orderUpdateStatus } from "../../redux/apis/orderAPI";
import { creareOrderEmail } from "../../utils/email";
import { useLocation, useNavigate } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";

const OrderDetail = () => {
  const [pending, setPending] = useState(true);
  const order = useSelector((state) => state.orderReducer.currentOrder);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const statusList = [
    { name: "Archive Order", value: 1 },
    { name: "Terminate Order", value: 2 },
    { name: "Cancel Order", value: 3 },
  ];
  const [selected, setSelected] = useState(statusList[0]);

  const fetchOrder = async () => {
    dispatch(orderGet());
  };

  const getOrderStatusBadge = (status) => {
    let badgeClasses = "p-1 text-xs font-semibold rounded h-fit";
    let badgeContent = "";

    switch (status) {
      case 0:
        badgeClasses += " bg-blue-500 text-white";
        badgeContent = "NEW";
        break;
      case 1:
        badgeClasses += " bg-green-500 text-white";
        badgeContent = "ARCHIVED";
        break;
      case 2:
        badgeClasses += " bg-yellow-500 text-white";
        badgeContent = "TERMINATED";
        break;
      case 3:
        badgeClasses += " bg-red-500 text-white";
        badgeContent = "CANCELED";
        break;
      default:
        badgeClasses += " bg-gray-500 text-white";
        badgeContent = "LEGACY";
    }

    return <div className={badgeClasses}>{badgeContent}</div>;
  };

  useEffect(() => {
    try {
      if (location.state.login) {
        fetchOrder();
      } else {
        navigate("/login/dashboard");
      }
    } catch (e) {
      navigate("/login/dashboard");
    }
  }, []);

  useEffect(() => {
    if (order.cart) {
      setPending(false);
    }
  }, [order]);

  const handleSubmit = () => {
    dispatch(orderUpdateStatus(order.cart.id, selected.value));
  };

  return (
    <div>
      {!pending ? (
        <div>
          <div className="flex justify-between" style={{ margin: "40px" }}>
            <div className="flex gap-2">
              <div
                onClick={() =>
                  navigate("/dashboard", { state: { login: true } })
                }
                className="text-blue-600 underline cursor-pointer"
              >
                Dashboard
              </div>
              / Order - {order.payment.order_id}{" "}
              {getOrderStatusBadge(order.payment.orderStatus)}
            </div>
            <div className="flex gap-4 justify-end">
              <Listbox value={selected} onChange={setSelected}>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate">{selected.name}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {statusList.map((st, indx) => (
                        <Listbox.Option
                          key={indx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-5 pr-4 ${
                              active
                                ? "bg-indigo-100 text-indigo-900"
                                : "text-gray-900"
                            }`
                          }
                          value={st}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {st.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              <div
                onClick={handleSubmit}
                className="cursor-pointer inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto hover:text-white"
              >
                Submit Action
              </div>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: creareOrderEmail(order.payment, order.cart),
            }}
          ></div>
        </div>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
};

export default OrderDetail;
