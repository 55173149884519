import React from "react";

const ProductDetailsSkeleton = () => {
  return (
    <div className="skeleton-container">
      <div className="mx-auto sm:px-6 lg:grid lg:grid-cols-2 lg:gap-x-8">
        {/* LEFT */}
        <div className="skeleton lg:py-10 lg:col-span-1 lg:pb-16 lg:pr-8 lg:pt-6">
          <div className="hidden lg:block aspect-h-4 aspect-w-3 overflow-hidden rounded-lg bg-gray-300"></div>
          <div className="mt-4">
            {/* Skeleton for carousel */}
            <div className="skeleton bg-gray-300 w-full h-36"></div>
          </div>
        </div>
        {/* RIGHT */}
        <div className="skeleton mt-4 lg:mt-0 lg:col-span-1 lg:pr-8">
          <hr className="mt-4 mb-4" />
          <p className="skeleton text-3xl tracking-tight mt-2 bg-gray-300 h-8 w-1/4"></p>

          <form className="mt-10">
            {/* Skeleton for variants */}
            <div className="skeleton bg-gray-300 w-full h-6"></div>

            <div className="mt-6">
              <h3 className="skeleton text-sm font-medium">Quantity</h3>
              <div className="mt-2 lg:w-1/2">
                {/* Skeleton for quantity button */}
                <div className="skeleton bg-gray-300 w-20 h-8"></div>
              </div>
            </div>
          </form>
          <div className="mt-10">
            <h3 className="skeleton text-sm font-medium">Highlights</h3>
            {/* Skeleton for highlights */}
            <div className="mt-4 space-y-2">
              <div className="skeleton bg-gray-300 w-full h-4"></div>
              <div className="skeleton bg-gray-300 w-full h-4"></div>
              <div className="skeleton bg-gray-300 w-full h-4"></div>
            </div>
          </div>
          <div className="mt-10">
            <h2 className="skeleton text-sm font-medium">Details</h2>
            {/* Skeleton for product details */}
            <div className="mt-4 space-y-6">
              <div className="skeleton bg-gray-300 w-full h-6"></div>
              <div className="skeleton bg-gray-300 w-full h-6"></div>
              <div className="skeleton bg-gray-300 w-full h-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsSkeleton;
