import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";

export default function Tooltip(props) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={`hidden absolute lg:block ${props.y}-0 ${props.x}-0`}>
      <div
        className="group relative flex"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span
          className={`absolute top-5 transition-all rounded bg-gray-700 p-2 text-xs text-white z-50 w-36`}
          style={{ scale: isHovered ? "100%" : "0" }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: props.message,
            }}
          ></div>
        </span>
        <div>
          <QuestionMarkCircleIcon className="w-5 h-5" />
        </div>
      </div>
    </div>
  );
}
