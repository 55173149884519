export const convertUtcToLocalTime = (utcTimeString) => {
  const offsetMinutes = new Date().getTimezoneOffset();
  const offsetHours = offsetMinutes / 60;
  const sign = offsetHours > 0 ? "-" : "+";
  const offsetFormatted = `${sign}${Math.abs(offsetHours)}`;

  const utcDate = new Date(utcTimeString);
  const localTime = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
  );

  const formattedLocalTime =
    localTime.getFullYear() +
    "-" +
    String(localTime.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(localTime.getDate()).padStart(2, "0") +
    " " +
    String(localTime.getHours()).padStart(2, "0") +
    ":" +
    String(localTime.getMinutes()).padStart(2, "0") +
    ":" +
    String(localTime.getSeconds()).padStart(2, "0");

  return formattedLocalTime;

  return formattedLocalTime;
};
