import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ShoppingBagIcon } from "@heroicons/react/20/solid";
import { cartItemsCatogeryCount, cartItemsCount } from "../../utils/cart";
import { getCartCookies } from "../../utils/cookies";

export default function CartIcon() {
  const cartIconRefresh = useSelector(
    (state) => state.pageReducer.cartIconRefresh
  );
  const [count, setCount] = useState(1);
  const navigate = useNavigate();

  const getSessionCart = async () => {
    // await getCartCookies("checkout");
    setCount(await cartItemsCatogeryCount());
  };

  useEffect(() => {
    //refresh the cookies
    getSessionCart();
  }, [cartIconRefresh]);

  return (
    <div
      className="text-sm font-semibold leading-6 ml-3 cursor-pointer"
      onClick={() => navigate(`/cart`)}
    >
      <div className="relative">
        <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" />
        {count > 0 && (
          <span className="cartCount absolute inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-white bg-indigo-400 rounded-full">
            {count}
          </span>
        )}
      </div>
    </div>
  );
}
