import React from "react";
import OrderList from "../../components/dashboard/orderList";
import SearchBox from "../../components/dashboard/searchBox";

const DashboardOrder = () => {
  return (
    <div>
      <div className="container mx-auto mt-8">
        <div className="mb-8">
          <SearchBox />
        </div>
        <OrderList />
      </div>
    </div>
  );
};

export default DashboardOrder;
