import { useNavigate } from "react-router-dom";
import React from "react";
import constructionImg from "../images/construction.jpg";

export default function ConstructionPage() {
  const navigate = useNavigate();
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-24 lg:px-8">
        <div className="text-center">
          <img className="w-1/2 m-auto" src={constructionImg} />
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <div
              onClick={() => navigate(`/`)}
              className="cursor-pointer rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline-indigo-600"
            >
              Go back home
            </div>
            <a
              href="mailto:sales@runningcount.com"
              className="text-sm font-semibold text-gray-900"
            >
              Contact Us <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
}
