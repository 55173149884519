import React, { useState } from "react";

const VariantsMultiCheckboxColor = (props) => {
  const [value_black, setValue_black] = useState(
    props.init[0].name.split(",")[0]
  );
  const [value_full, setValue_full] = useState(
    props.init[0].name.split(",")[1]
  );
  console.log(props);
  const handleChange_black = () => {
    const newValue = value_black === "Black Print" ? "" : "Black Print";
    setValue_black(newValue);
    props.setVariants(props.name, `${newValue},${value_full}`);
  };

  const handleChange_full = () => {
    const newValue =
      value_full === "Full-colour Print" ? "" : "Full-colour Print";
    setValue_full(newValue);
    props.setVariants(props.name, `${value_black},${newValue}`);
  };

  return (
    <div>
      <h3 className="text-sm font-medium">{props.name} (must choose one)</h3>

      <div className="flex gap5em">
        <div
          id={`v-${props.name.replace(/\s+/g, "-")}`}
          className="flex items-center mt-4 block text-sm font-medium"
        >
          <input
            id={`${props.name}_black`}
            onChange={handleChange_black}
            checked={value_black === "Black Print" ? true : false}
            type="checkbox"
            className="form-checkbox h-5 w-5"
          />
          <label className="ml-2">Black Print</label>
        </div>
        <div
          id={`v-${props.name.replace(/\s+/g, "-")}`}
          className="flex items-center mt-4 block text-sm font-medium"
        >
          <input
            id={`${props.name}_full`}
            onChange={handleChange_full}
            checked={value_full === "Full-colour Print" ? true : false}
            type="checkbox"
            className="form-checkbox h-5 w-5"
          />
          <label className="ml-2">Full-colour Print</label>
        </div>
      </div>
    </div>
  );
};

export default VariantsMultiCheckboxColor;
