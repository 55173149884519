import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/20/solid";

const Carousel = (props) => {
  const settings = {
    className: "center",
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 5,
    swipeToSlide: false,
  };

  const settingsMobile = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    swipeToSlide: true,
  };

  const sliderRef = React.createRef();
  const sliderRef_Mobile = React.createRef();

  const goToPrev = () => {
    sliderRef.current.slickPrev();
    sliderRef_Mobile.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
    sliderRef_Mobile.current.slickNext();
  };

  const handleImageClick = (id, src) => {
    for (let i = 0; i < props.images.length; i++) {
      const imageContainer = document.getElementById(`variant_img_${i}`);
      if (i !== id && imageContainer) {
        imageContainer.style.borderBottom = "";
      }
      if (i === id && imageContainer) {
        imageContainer.style.borderBottom = "2px solid #2b456b";
      }
    }
    props.onChangeMainImg(src);
  };

  return (
    <div className="product-variant-slide">
      <div className="relative">
        <Slider ref={sliderRef} {...settings}>
          {props.images.map((image, index) => (
            <div
              key={index}
              className="cursor-pointer flex items-center product-img-container"
              onClick={() => handleImageClick(index, image.src)}
            >
              <img
                src={image.src}
                alt={image.src}
                id={`variant_img_${index}`}
              />
            </div>
          ))}
        </Slider>
        {/* <div
          style={{ top: "calc(25%)" }}
          className="absolute w-full text-center"
        >
          <button
            onClick={goToPrev}
            className="text-black text-2xl p-2 absolute left-0 hover:opacity-100 focus:outline-none"
          >
            <ArrowLeftCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <button
            onClick={goToNext}
            className="text-black text-2xl p-2 absolute right-0 hover:opacity-100 focus:outline-none"
          >
            <ArrowRightCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div> */}
      </div>
      {/* MOBILE */}
      {/* <div className="lg:hidden relative">
        <Slider ref={sliderRef_Mobile} {...settingsMobile}>
          {props.images.map((image, index) => (
            <div key={index}>
              <img src={image.src} alt={image.src} />
            </div>
          ))}
        </Slider>
        <div className="absolute top-1/2 transform -translate-y-1/2 flex justify-between w-full">
          <button
            onClick={goToPrev}
            className="text-black text-2xl p-2 hover:opacity-100 focus:outline-none"
          >
            <ArrowLeftCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <button
            onClick={goToNext}
            className="text-black text-2xl p-2 hover:opacity-100 focus:outline-none"
          >
            <ArrowRightCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Carousel;
