import createAxios from "../../utils/createAxios";
import { category_set, page_refresh } from "../actions/pageAction";
import axios from "axios";

//set current category on product page
export const categorySet = (data) => async (dispatch) => {
  dispatch(category_set(data));
};

//page refresh control
export const pageRefresh = () => async (dispatch) => {
  dispatch(page_refresh());
};

export const contactSubmit = async (data) => {
  const res = await createAxios.post("/contact", data);
  console.log("contact submit", res);
  return res.data;
};
