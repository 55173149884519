import * as React from "react";
import Footer from "../components/menu/footer";
import Menu from "../components/menu/menu";

const Layout = ({ children }) => {
  return (
    <div>
      <Menu />
      <main className="mx-auto max-w-9xl px-6 lg:px-16 lg:pt-24 lg:pb-24 pt-10 pb-10">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
