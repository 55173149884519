import React, { useEffect, useRef, useState } from "react";
import QuantityButton from "../../components/product/quanyityButton";
import Layout from "../Layout";
import Variants from "../../components/product/variants";
import { useDispatch } from "react-redux";
import { cartUpdate, cartUpdateProduct } from "../../redux/apis/orderAPI";
import { productGet } from "../../redux/apis/productAPI";
import { findObjectInArray } from "../../utils/object";
import { calculateTotal, listenBibSnE } from "../../utils/product";
import ProductDetailsSkeleton from "../../components/skeleton/productDetails_Skeleton";
import PriceRangeTable from "../../components/product/priceRangeTable";
import { isNull, isValidBibStartandEnd } from "../../utils/validation";
import { useNavigate, useLocation } from "react-router";

export default function EditProduct() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentProduct = location.state.currentProduct;
  const currentIndex = location.state.currentIndex;
  const currentCartID = location.state.currentCartID;
  const currentCart = location.state.currentCart;
  const [product, setProduct] = useState({});
  const [mainImage, setMainImage] = useState("");
  const [errorMessage, setErrorMessage] = useState({ enable: false, msg: "" });
  const pending = useRef(true);
  const dispatch = useDispatch();
  const numberChange = useRef(false);
  // cart detail
  const [v, setV] = useState([]);
  const [q, setQ] = useState(0);
  const [totalQ, setTotalQ] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productData = await productGet();
        setProduct(productData);

        const updatedVariants = Object.keys(currentProduct.variants).reduce(
          (acc, variantKey) => {
            const variantOptions = currentProduct.variants[variantKey];
            const firstInStockOption = variantOptions.find(
              (option) => option.inStock == "1"
            );
            if (firstInStockOption) {
              acc.push({ [variantKey]: firstInStockOption.name });
            }
            return acc;
          },
          []
        );
        setV(updatedVariants);
        setMainImage(productData.image[0].src);
        if (currentProduct.variants["How Many Sets?"]) {
          setQ(
            isNaN(Number(currentProduct.variants["How Many Sets?"][0].name))
              ? 0
              : Number(currentProduct.variants["How Many Sets?"][0].name)
          );
        } else if (currentProduct.variants["How Many Individual Numbers?"]) {
          setQ(
            isNaN(
              Number(
                currentProduct.variants["How Many Individual Numbers?"][0].name
              )
            )
              ? 0
              : Number(
                  currentProduct.variants["How Many Individual Numbers?"][0]
                    .name
                )
          );
        } else if (currentProduct.variants["How Many Rolls?"]) {
          setQ(
            isNaN(Number(currentProduct.variants["How Many Rolls?"][0].name))
              ? 0
              : Number(currentProduct.variants["How Many Rolls?"][0].name)
          );
        } else {
          setQ(currentProduct.quantity);
        }

        pending.current = false;
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchData();
  }, []);

  //listening on input
  useEffect(() => {
    listenBibSnE(handleNumberChange, numberChange.current);
  }, []);
  const handleNumberChange = () => {
    numberChange.current = true;
  };

  //handle price change when variants change, only happens when product type is bib number
  useEffect(() => {
    if (!pending.current) {
      let newQuantity = q;
      if (product.productType === "plain") {
        newQuantity =
          Number(findObjectInArray(v, "End Number") ?? 0) -
          Number(findObjectInArray(v, "Start Number") ?? 0);

        //check start and end number
        if (newQuantity <= 0 && numberChange.current) {
          newQuantity = 0;
          setErrorMessage({
            enable: true,
            msg: "End Number should be greater than Start Number",
          });
        } else {
          setErrorMessage({ enable: false, msg: "" });
          newQuantity += 1;
          newQuantity = newQuantity * q;
        }
      }
      handlePriceChange(newQuantity);
    }
  }, [v, q]);

  const handleQuantityBox = (quan) => {
    setQ(quan);
    if (/individual/i.test(product.name)) {
      setCurrentVariants({
        "How Many Individual Numbers?": quan,
      });
    } else if (/roll/i.test(product.name)) {
      setCurrentVariants({
        "How Many Rolls?": quan,
      });
    } else {
      setCurrentVariants({
        "How Many Sets?": quan,
      });
    }
  };

  const updateCart = async (e) => {
    if (e) e.preventDefault();
    if (totalQ < 1) {
      setErrorMessage({
        enable: true,
        msg: `${
          product.productType === "plain"
            ? "End Number should be greater than Start Number and cannot be 0"
            : "Quantity cannot be 0"
        }`,
      });
      return;
    }
    if (findObjectInArray(v, "End Number")) {
      if (
        isValidBibStartandEnd(
          findObjectInArray(v, "Start Number"),
          findObjectInArray(v, "End Number")
        )
      ) {
      } else {
        setErrorMessage({
          enable: true,
          msg: "End Number should be greater than Start Number and cannot be 0",
        });
        return;
      }
    }
    let cartValid = { valid: false, data: "" };
    cartValid = await cartUpdate(product, v, totalQ, price, currentIndex);
    if (cartValid.valid) {
      setErrorMessage({ enable: false, msg: "" });
      await cartUpdateProduct(
        currentCart,
        cartValid.data,
        currentIndex,
        "update"
      );
      navigate(`/cart?id=${currentCartID}`);
    } else {
      setErrorMessage({
        enable: true,
        msg: cartValid.data + " field is invalid",
      });
    }
  };

  const handlePriceChange = (quantity) => {
    let p = calculateTotal(quantity, product.priceTable, v, product.setupFee);
    setPrice(p.total);
    setTotalQ(quantity);
  };

  // const onChangeMainImg = (url) => {
  //   setMainImage(url);
  // };

  const setCurrentVariants = (data) => {
    const updatedVariants = [...v];
    Object.keys(data).forEach((key) => {
      const variantIndex = updatedVariants.findIndex(
        (variant) => key in variant
      );
      let newValue = data[key];
      if (typeof newValue !== "object") {
        newValue = String(newValue);
      }
      if (variantIndex !== -1) {
        updatedVariants[variantIndex] = {
          ...updatedVariants[variantIndex],
          [key]: newValue,
        };
      } else {
        updatedVariants.push({ [key]: newValue });
      }
    });
    setV(updatedVariants);
  };

  return (
    <Layout className="bg-white">
      {!pending.current ? (
        <div className="mx-auto sm:px-6 grid lg:grid-cols-2 gap-x-8">
          {/*LEFT*/}
          <div className="productPanel1 lg:py-10 lg:col-span-1 lg:pb-16 lg:pr-8 lg:pt-6">
            <div className="hidden lg:block aspect-h-4 aspect-w-3 rounded-lg">
              <img
                src={mainImage}
                className="w-full object-cover object-center"
                alt={mainImage}
              />
            </div>
            {/* <div className="mt-4">
                <Carousel
                  images={product.image}
                  onChangeMainImg={onChangeMainImg}
                />
              </div> */}
            <div className="mt-10">
              <h3 className="text-sm font-medium">Details</h3>
              <div className="mt-4">
                <ul className="list-disc space-y-2 pl-4 text-sm">
                  {!isNull(product.highlights) &&
                    product.highlights.map((highlight) => (
                      <li key={highlight} className="text-gray-400">
                        <span className="text-gray-600">{highlight}</span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <PriceRangeTable data={product.priceTable} />
          </div>
          {/*RIGHT*/}
          <div className="productPanel2 mt-4 lg:mt-0 lg:col-span-1 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight sm:text-3xl">
              {product.name}
            </h1>
            {product.subTitle && (
              <div
                dangerouslySetInnerHTML={{
                  __html: product.subTitle,
                }}
              ></div>
            )}
            <hr className="mt-4 mb-4" />
            <p className="flex justify-between mt-2">
              <span className="text-3xl tracking-tight">${price}</span>
            </p>

            <form className="mt-10">
              <Variants
                original={product.variants}
                init={currentProduct.variants}
                setCurrentVariants={setCurrentVariants}
                productName={product.name}
              />
              <div className="mt-6">
                <h3 className="text-sm font-medium">
                  {product.productType === "dependency"
                    ? "Quantity"
                    : /individual/i.test(product.name)
                    ? "How Many Individual Numbers?"
                    : /roll/i.test(product.name)
                    ? "How Many Rolls"
                    : "How Many Sets?"}
                </h3>
                <div className="mt-2 lg:w-1/2">
                  <QuantityButton
                    qua={q}
                    setQ={handleQuantityBox}
                    min={product.unitSet}
                    unitSet={product.unitSet}
                    max={product.maxThreshold}
                  />
                </div>
                <p className="mt-4 font-bold">(Total Quantity: {totalQ})</p>
              </div>
              <div className="mt-8">
                {errorMessage.enable ? (
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 rounded relative"
                    role="alert"
                  >
                    <strong className="font-bold">Error! </strong>
                    <span className="block sm:inline">{errorMessage.msg}</span>
                  </div>
                ) : null}
                <button
                  onClick={(event) => updateCart(event)}
                  className="mt-4 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-white hover:text-indigo-500 hover:border-indigo-500 focus:outline-none focus:ring-indigo-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Update Cart
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <ProductDetailsSkeleton />
      )}
    </Layout>
  );
}
