import {
  API_PENDING,
  CART_ICON_REFRESH,
  CATALOG_SET,
  PAGE_REFRESH,
  SHIPPING_FEE_PENDING,
} from "../actions/types";

const initialState = {
  category: { name: "Bib Numbers", id: 1 },
  refresh: true,
  cartIconRefresh: true,
  shippingFeePending: false,
  apiRefresh: false,
};

export const pageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CATALOG_SET:
      return {
        ...state,
        category: payload,
      };
    case PAGE_REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case CART_ICON_REFRESH:
      return {
        ...state,
        cartIconRefresh: !state.cartIconRefresh,
      };
    case SHIPPING_FEE_PENDING:
      return {
        ...state,
        shippingFeePending: payload,
      };
    case API_PENDING:
      return {
        ...state,
        apiRefresh: payload,
      };
    default:
      return state;
  }
};
