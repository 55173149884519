import {
  CART_CHANGE,
  ORDER_APPEND,
  ORDER_INIT,
  ORDER_LOADING,
  ORDER_UPDATESTATUS,
  SHIPPING_FEE,
} from "../actions/types";

const initialState = {
  orders: [],
  maxPage: 1,
  currentOrder: {},
  shippingFee: 0,
  orderLoading: false,
  cart: {
    id: "",
    products: [],
  },
};

export const orderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ORDER_INIT:
      return {
        ...state,
        currentOrder: payload,
      };
    case ORDER_APPEND:
      const page = payload.page - 1;
      const original = [...state.orders];

      if (page < original.length) {
        original[page] = payload.data;
      } else {
        original.push(payload.data);
      }
      return {
        ...state,
        orders: original,
        maxPage: payload.data.maxPage,
      };

    case ORDER_UPDATESTATUS:
      const updatedOrders = state.orders.map((order) => {
        if (
          order.payment &&
          order.payment.orderStatus !== undefined &&
          order.payment.orderStatus !== null
        ) {
          return order.payment.order_id === payload.order_id
            ? {
                ...order,
                payment: { ...order.payment, orderStatus: payload.orderStatus },
              }
            : order;
        }
        return order;
      });

      return {
        ...state,
        orders: updatedOrders,
        currentOrder: {
          ...state.currentOrder,
          payment: {
            ...state.currentOrder.payment,
            orderStatus: payload.orderStatus,
          },
        },
      };
    case SHIPPING_FEE:
      return {
        ...state,
        shippingFee: payload,
      };
    case ORDER_LOADING:
      return {
        ...state,
        orderLoading: payload,
      };
    case CART_CHANGE:
      return {
        ...state,
        cart: payload,
      };
    default:
      return state;
  }
};
