import React from "react";
import { useNavigate } from "react-router-dom";

export default function ProductList(props) {
  const navigate = useNavigate();
  return (
    <div className="bg-white">
      <div className="mx-auto">
        <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 gap5em">
          {props.productArray.map((product, index) => (
            <div
              key={product.id}
              className="col-span-1 flex flex-col border border-gray-100"
            >
              <div
                onClick={() =>
                  navigate(
                    `/${
                      product.productType === "custom"
                        ? `custom-product`
                        : `product`
                    }?id=${product.id}`
                  )
                }
                className="group relative cursor-pointer flex-grow flex flex-col items-center"
              >
                <div className="overflow-hidden rounded-md lg:aspect-none group-hover:opacity-75 mt-4">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="w-auto object-contain object-center"
                    style={{ height: "180px" }}
                  />
                </div>
                <div className="mt-4 text-center flex-grow flex flex-col justify-between p-4 w-full">
                  <div>
                    <p className="font-bold">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </p>
                    <p className="my-2 text-sm">
                      {product.description.split("<br/>")[0]}
                    </p>
                  </div>
                  <div className="mt-auto font-bold text-indigo-500">
                    <p className="font-medium">
                      {product.minPrice === product.maxPrice
                        ? "$" + Number(product.minPrice).toFixed(2)
                        : "From $" +
                          Number(product.minPrice).toFixed(2) +
                          " - $" +
                          Number(product.maxPrice).toFixed(2)}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
