import React from "react";
import Layout from "./Layout";
import MapShipping from "../images/MapShipping.jpg";

const faqData = [
  {
    question: "What are bib numbers?",
    answer:
      "A bib number is normally used in running events where each runner has to have a different number for scoring purposes. Bibs have holes in each corner so that they can be safely pinned to the athlete's front or back. Bib numbers can be used for any type of event where you need to differentiate people by way of a highly visible number.",
  },
  {
    question: "Where are your bibs shipped from?",
    answer: `
      Windsor, Ontario, Canada and Detroit, Michigan, USA<br/><br/>
      The majority of orders are shipped from Canada.`,
  },
  {
    question: "How long will shipping take, and shipping rate?",
    answer: `<p>
    We use UPS, FedEx, Purolator and Canada Post choosing the best rate for our customers.  Ground Shipping 1-7 days. We also do overnight express shipping if needed. Shipping estimates subject on specifics of order.
  </p>`,
  },
  {
    question: "How do I place an order?",
    answer: `<p>
    1. Online ordering<br>
    Our primary (and preferred) method uses our newly updated <a href="order.php">secure online ordering</a>. Please take a look and if you have any questions or need help with your order, email or call and we would be glad to help. <br><br>
    
    2. Email: <a href="mailto: sales@runningcount.com">Click here</a> to email us.<br><br> 
    
    3. Phone: 1-866-542-9099 <br><br>
  </p>`,
  },
  {
    question:
      "How long will it take to print the numbers and for us to receive them?",
    answer: `Our standard timeline for custom bibs is 1-3 weeks to create and approve a proof, print the bibs and complete the shipping process. You may confirm this timeline when you place your order.<br/><br/>Plain stock bibs are kept in stock so can ship out the next business day.`,
  },
  {
    question: "What type of artwork should I send?",
    answer: `Logos must be in high resolution (300 dpi) JPG, TIFF, AI, EPS, or PDF format.
    `,
  },
  {
    question: "What is the standard process after ordering the bibs?",
    answer: `Before printing, we will send you proof via e-mail for your approval. For personalized bibs with the participants' names, we will need your order and entrant database by a certain date before your in-hand date. Of course, this means that only pre-registered participants up to a certain date will have their name custom printed on their bibs.
    `,
  },
  {
    question: "Is there minimum order?",
    answer: `Our minimum order will be 50 bibs for custom bibs.
    `,
  },
];

const FAQ = () => {
  return (
    <Layout>
      <h1 className="text-2xl font-bold pb-6 col-span-2">
        Frequently Asked Questions
      </h1>
      <hr />
      <div className="mx-auto mt-4 lg:grid gap-4 lg:grid-cols-2">
        {faqData.map((item, index) => (
          <div className="px-10 py-6" key={index}>
            <h3 className="font-semibold mb-2 question-title">
              {item.question}
            </h3>
            <div dangerouslySetInnerHTML={{ __html: item.answer }} />
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default FAQ;
