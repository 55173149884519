import { useDispatch } from "react-redux";
import {
  createCookies,
  getCartCookies,
  isCookiesValid,
  removeCookies,
  updateCookies,
} from "../../utils/cookies";
import createAxios from "../../utils/createAxios";
import { creareOrderEmail } from "../../utils/email";
import { isNull, isValidAddress } from "../../utils/validation";
import {
  cart_change,
  order_append,
  order_init,
  order_loading,
  order_updateStatus,
  shipping_fee,
} from "../actions/orderAction";
import {
  api_pending,
  cartIcon_refresh,
  page_refresh,
  shipping_fee_pending,
} from "../actions/pageAction";
import { pageRefresh } from "./pageAPI";
import { dependencyGet } from "./productAPI";

const ignoreList = ["Special Instructions"];

//check cart valid
const cartValid = (variantArray) => {
  let isValid = true;
  const variants = variantArray.reduce((acc, item) => {
    const key = Object.keys(item)[0];
    const value = item[key];
    if (!ignoreList.includes(key) && isNull(value)) {
      isValid = key;
    }
    acc[key] = value;
    return acc;
  }, {});

  if (isValid !== true) {
    return isValid;
  }
  return variants;
};

//add to cart
export const cartAdd = async (
  product,
  variantArray,
  quantity,
  productTotal,
  weight
) => {
  try {
    const variants = cartValid(variantArray);
    if (typeof variants === "string") {
      return { valid: false, data: variants };
    }
    //get dependencies by product type
    const dependencies = await dependencyGet(product.productType);

    const productDetail = {
      id: product.id,
      name: product.name,
      productType: product.productType,
      total: productTotal,
      weight: weight,
      image: product.image[0],
      variants,
      quantity: quantity,
      dependencies: dependencies,
    };

    let userID = isCookiesValid("checkout");
    if (userID == null) {
      userID = await createCookies(
        { id: userID, products: [productDetail] },
        "checkout",
        "create"
      );
    } else {
      let currentCart = await getCartCookies("checkout");
      currentCart.products.push(productDetail);
      await updateCookies(currentCart);
    }
    // dispatch(page_refresh());
    return { valid: true, data: userID };
  } catch (e) {
    console.error(e);
    return { valid: false, data: "error" };
  }
};

//edit and update cart
export const cartUpdate = async (
  product,
  variantArray,
  quantity,
  productTotal,
  productIndex
) => {
  try {
    const variants = cartValid(variantArray);
    if (typeof variants == "string") {
      return { valid: false, data: variants };
    }
    let currentCart = await getCartCookies("checkout");
    if (typeof currentCart === "string") {
      currentCart = JSON.parse(currentCart);
    }
    const dependencies = currentCart.products[productIndex].dependencies;

    const productDetail = {
      id: product.id,
      name: product.name,
      productType: product.productType,
      total: productTotal,
      image: product.image[0],
      variants,
      quantity: quantity,
      dependencies: dependencies,
    };
    return { valid: true, data: productDetail };
  } catch (e) {
    console.error(e);
    return { valid: false, data: "error" };
  }
};

//update product price in cart
export const cartUpdateProduct = async (
  currentCart,
  product,
  index,
  handle
) => {
  if (handle === "rm") {
    currentCart.products.splice(index, 1);
    if (currentCart.products.length === 0) {
      removeCookies("checkout");
    }
  } else {
    currentCart.products[index] = product;
  }
  await updateCookies(currentCart);
};

//remove all product in cart
export const cartRemoveAll = (checkoutState) => async (dispatch) => {
  let currentCart = await getCartCookies(checkoutState);
  currentCart = [];
  removeCookies(checkoutState);
  await updateCookies(currentCart);
  dispatch(cartIcon_refresh());
};

//add to quote
export const quoteAdd = async (
  product,
  variantArray,
  quantity,
  productTotal
) => {
  try {
    const variants = cartValid(variantArray);
    if (typeof variants === "string") {
      return { valid: false, data: variants };
    }

    const productDetail = {
      id: product.id,
      name: product.name,
      productType: product.productType,
      total: productTotal,
      image: product.image[0],
      variants,
      quantity: quantity,
      dependencies: [],
    };
    removeCookies("quote");
    const userID = await createCookies(
      { id: null, products: [productDetail] },
      "quote"
    );
    return { valid: true, data: userID };
  } catch (e) {
    console.error(e);
    return { valid: false, data: "error" };
  }
};

//create order after checkout
// export const orderCreate = (data) => async (dispatch) => {
//   dispatch(order_loading(true));
//   const res = await createAxios.post("/createorder", data);
//   console.log("order create", res);
//   dispatch(order_loading(false));
//   return res.data;
// };

export const quoteCreate = async (data) => {
  const res = await createAxios.post("/createquote", data);
  console.log("quote create", res);
  return res.data;
};

export const orderCreate = async (data) => {
  const res = await createAxios.post("/createorder", data);
  console.log("order create", res);
  return res.data;
};

export const orderGetAll = (page) => async (dispatch) => {
  const res = await createAxios.get(`/allorders?page=${page}`);
  console.log("get all orders", res);

  dispatch(order_append(res.data.data, page));
  dispatch(pageRefresh());
};

export const orderGet = () => async (dispatch) => {
  const searchParams = window.location.search;
  let id = searchParams.split("=")[1];

  const res = await createAxios.get("/getorder", { params: { id: id } });
  console.log("get order", res.data.data);
  dispatch(order_init(res.data.data));
  return res.data.data;
};

export const orderUpdateStatus = (order_id, status) => (dispatch) => {
  console.log("update", order_id, status);
  dispatch(order_updateStatus(order_id, status));
};

export const getShippingRate = (address, weight) => async (dispatch) => {
  const valid = isValidAddress(address);
  console.log("getShippingRate", valid);
  if (valid) {
    dispatch(shipping_fee_pending(true));
    const payload = {
      RateRequest: {
        Request: {
          TransactionReference: {
            CustomerContext: "CustomerContext",
          },
        },
        Shipment: {
          Shipper: {
            Address: {
              AddressLine: ["2355 Edna Street Unit B"],
              City: "WINDSOR",
              StateProvinceCode: "ON",
              PostalCode: "N8Y 4W8",
              CountryCode: "CA",
            },
          },
          ShipTo: {
            Name: "ShipToName",
            Address: {
              AddressLine: [address.address],
              City: address.city,
              StateProvinceCode: address.state,
              PostalCode: address.code,
              CountryCode: address.country,
            },
          },
          Service: {
            Code: "11",
          },
          Package: {
            PackagingType: {
              Code: "00",
            },
            PackageWeight: {
              UnitOfMeasurement: {
                Code: "LBS",
              },
              Weight: weight,
            },
          },
        },
      },
    };
    const res = await createAxios.post("/getrates", payload);
    try {
      console.log("getShippingRate", res);
      dispatch(
        shipping_fee(
          res.data.data.RateResponse.RatedShipment.TotalCharges.MonetaryValue
        )
      );
    } catch (e) {
      console.log("no rate response");
      dispatch(shipping_fee("Error"));
    }

    dispatch(shipping_fee_pending(false));
  }
};

export const initShippingRate = () => (dispatch) => {
  dispatch(shipping_fee(0));
};

export const updateCart = async (userID, data) => {
  const res = await createAxios.post(`/updcart`, data);
  console.log("update cart API", res);
};

export const getCart = async (userID) => {
  try {
    const res = await createAxios.get(`/getcart?id=${userID}`);
    return res.data.data.cart;
  } catch (e) {
    // console.error(e);
    return {};
  }
};

export const createCart = async (userID, data) => {
  const res = await createAxios.post(`/crecart`, data);
  console.log("create cart API", res);
};

export const deleteCart = async (userID) => {
  const data = { id: userID };
  const res = await createAxios.post(`/delcart`, data);
  console.log("delete cart API", res);
};
