import React, { useState } from "react";
import { cmykToHex, hexToCmyk } from "../../utils/product";

const VariantsCheckboxColor = (props) => {
  const [checkboxvalue, setCheckboxValue] = useState(
    props.init[0].name === "false" ? false : true
  );

  //color value
  const [value, setValue] = useState(
    props.init[0].name ? cmykToHex(props.init[0].name) : "#ffffff"
  );
  // const [noColor, setNoColor] = useState(props.init[0].name === "false");

  const handleCheckboxChange = () => {
    const currentStatus = checkboxvalue;
    if (currentStatus === false) {
      setCheckboxValue(true);
      setValue("#ffffff");
      props.setVariants(props.name, hexToCmyk("#ffffff"));
    } else {
      setCheckboxValue(false);
      props.setVariants(props.name, "false");
    }
  };

  const handleColorChange = (value) => {
    setValue(value);
    props.setVariants(props.name, hexToCmyk(value));
  };

  return (
    <div>
      <div
        id={`v-${props.name.replace(/\s+/g, "-")}`}
        className="flex items-center mt-4 block text-sm font-medium"
      >
        <input
          id={props.name}
          onChange={handleCheckboxChange}
          checked={checkboxvalue}
          type="checkbox"
          className="form-checkbox h-5 w-5"
        />
        <label htmlFor={props.name} className="ml-2">
          {props.name}
        </label>
      </div>
      {checkboxvalue === true && (
        <div className="px-7 py-2">
          <p className="text-sm text-gray-600">Select a colour</p>
          <div className="gap-2 items-center">
            <input
              onChange={(e) => handleColorChange(e.target.value)}
              type="color"
              value={value}
              className="h-10 w-10 cursor-pointer bg-white"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VariantsCheckboxColor;
