import React, { Fragment, useEffect, useState } from "react";
import Layout from "./Layout";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import ProductList from "../components/productList";
import { categorySet } from "../redux/apis/pageAPI";
import { categoriesInit } from "../redux/apis/productAPI";
import { findProductArrIncategories } from "../utils/product";
import CategorySkeleton from "../components/skeleton/category_Skeleton";
import tempData from "../utils/temp.json";
import Gallery from "../components/gallery";

export default function Categories() {
  const categories = useSelector((state) => state.productReducer.categories);
  const refresh = useSelector((state) => state.productReducer.refresh);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const category = useSelector((state) => state.pageReducer.category);
  const dispatch = useDispatch();

  const setCategory = (e, name, category_id) => {
    if (e) e.preventDefault();
    dispatch(categorySet({ name: name, id: category_id }));
  };

  const fetchData = async () => {
    //init category
    dispatch(categoriesInit());
  };

  useEffect(() => {
    if (categories.length === 0) {
      fetchData();
    }
  }, []);

  return (
    <Layout className="bg-white">
      {!refresh ? (
        <div>
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setMobileFiltersOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="text-lg font-medium text-gray-900">
                        Filters
                      </h2>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Filters */}
                    <form className="mt-4 border-t border-gray-200">
                      <h3 className="sr-only">{category}</h3>
                      <ul className="px-2 py-3 font-medium text-gray-900">
                        {tempData.categories.map((category) => (
                          <li key={category.name}>
                            <button
                              className="block px-2 py-3"
                              onClick={(e) =>
                                setCategory(e, category.name, category.id)
                              }
                            >
                              {category.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <main className="mx-auto">
            <div className="hidden lg:block flex items-baseline justify-between border-b border-gray-200 pb-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                {category.name}
              </h1>
            </div>
            <div className="lg:hidden lg:hidden flex flex-col items-center border-b border-gray-200 pb-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                {category.name}
              </h1>
            </div>

            <section aria-labelledby="products-heading" className="pt-6">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                {/* Filters */}
                <form className="hidden lg:block">
                  <h3 className="font-bold">Categories</h3>
                  <ul className="space-y-2 border-b border-gray-200 pb-6 text-gray-900 mt-4">
                    {tempData.categories.map((category) => (
                      <li key={category.name}>
                        <button
                          className="text-gray-600"
                          onClick={(e) =>
                            setCategory(e, category.name, category.id)
                          }
                        >
                          {category.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </form>
                {/* MOBILE */}
                <form className="lg:hidden flex flex-col items-center">
                  <div className="flex flex-wrap lg:justify-center gap-2">
                    {tempData.categories.map((category) => (
                      <button
                        key={category.name}
                        className="px-3 py-2 rounded-md border border-gray-300 bg-gray-100 text-gray-600"
                        onClick={(e) =>
                          setCategory(e, category.name, category.id)
                        }
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                  <hr className="mt-6" />
                </form>

                {/* Product grid */}
                <div className="lg:col-span-3">
                  {category.id === 4 ? (
                    <Gallery />
                  ) : (
                    <ProductList
                      productArray={findProductArrIncategories(
                        categories,
                        category.id
                      )}
                    />
                  )}
                </div>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <CategorySkeleton />
      )}
    </Layout>
  );
}
