import React, { useEffect, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { orderGetAll } from "../../redux/apis/orderAPI";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNull } from "../../utils/validation";
import { convertUtcToLocalTime } from "../../utils/timezone";

const OrderList = () => {
  const [pending, setPending] = useState(false);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refresh = useSelector((state) => state.pageReducer.refresh);
  const orderData = useSelector((state) => state.orderReducer);

  const getOrderStatusBadge = (status) => {
    let badgeClasses = "p-1 text-xs font-semibold rounded";
    let badgeContent = "";

    switch (status) {
      case 0:
        badgeClasses += " bg-blue-500 text-white";
        badgeContent = "NEW";
        break;
      case 1:
        badgeClasses += " bg-green-500 text-white";
        badgeContent = "ARCHIVED";
        break;
      case 2:
        badgeClasses += " bg-yellow-500 text-white";
        badgeContent = "TERMINATED";
        break;
      case 3:
        badgeClasses += " bg-red-500 text-white";
        badgeContent = "CANCELED";
        break;
      default:
        badgeClasses += " bg-gray-500 text-white";
        badgeContent = "LEGACY";
    }

    return <span className={badgeClasses}>{badgeContent}</span>;
  };

  const fetchOrders = (p) => {
    dispatch(orderGetAll(p));
  };

  const handlePage = (handle) => {
    let newPage = 0;
    if (handle === "next" && page < maxPage) {
      newPage = page + 1;
      setPage(newPage);
    }
    if (handle === "prev" && page > 1) {
      newPage = page - 1;
      setPage(newPage);
    }
    if (newPage > 0) {
      if (isNull(orderData.orders[newPage - 1])) {
        setPending(true);
        fetchOrders(newPage);
      } else {
        setPending(false);
      }
    }
  };

  useEffect(() => {
    fetchOrders(1);
  }, []);

  useEffect(() => {
    setOrders(orderData.orders);
    setMaxPage(orderData.maxPage);
    setPending(false);
  }, [refresh]);

  const handleRedirect = (id, orderStatus) => {
    if (orderStatus === "") {
      console.log("this is legacy");
    } else {
      navigate(`/dashboard/orderdetail?id=${id}`, { state: { login: true } });
    }
  };

  return (
    <div>
      <table className="min-w-full border border-gray-200 divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="py-2 px-4 text-left">Create Date</th>
            <th className="py-2 px-4 text-left">Update By</th>
            <th className="py-2 px-4 text-left">Customer</th>
            <th className="py-2 px-4 text-left">Subtotal</th>
            <th className="py-2 px-4 text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          {orders[page - 1] && !pending ? (
            orders[page - 1].orders.map((order) => (
              <tr
                key={order.order_id}
                className="hover:bg-gray-100 transition duration-300 cursor-pointer"
                onClick={() =>
                  handleRedirect(order.order_id, order.orderStatus)
                }
              >
                <td className="py-2 px-4">
                  {convertUtcToLocalTime(order.createdDate)}
                </td>
                <td className="py-2 px-4">
                  {convertUtcToLocalTime(order.updatedDate)}
                </td>
                <td className="py-2 px-4">
                  {order.firstName} {order.lastName}
                </td>
                <td className="py-2 px-4">
                  ${Number(order.subtotal).toFixed(2)}
                </td>
                <td className="py-2 px-4">
                  {getOrderStatusBadge(order.orderStatus)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="animate-pulse p-4">
                <div className="h-8 bg-gray-300"></div>
              </td>
              <td className="animate-pulse p-4">
                <div className="h-8 bg-gray-300"></div>
              </td>
              <td className="animate-pulse p-4">
                <div className="h-8 bg-gray-300"></div>
              </td>
              <td className="animate-pulse p-4">
                <div className="h-8 bg-gray-300"></div>
              </td>
              <td className="animate-pulse p-4">
                <div className="h-8 bg-gray-300"></div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="mt-6 flex items-center justify-center">
        <div
          className={`px-3 py-1 border border-gray-300 rounded-md mr-2 ${
            page <= 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => handlePage("prev")}
          disabled={page > 1}
        >
          <ArrowLeftIcon className="h-5 w-5" />
        </div>
        <span className="text-gray-600">
          {page} of {maxPage} page{maxPage > 1 && "s"}
        </span>
        <div
          className={`px-3 py-1 border border-gray-300 rounded-md ml-2 ${
            page >= maxPage ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => handlePage("next")}
          disabled={page < maxPage}
        >
          <ArrowRightIcon className="h-5 w-5" />
        </div>
      </div>
    </div>
  );
};

export default OrderList;
