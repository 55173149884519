import { pageReducer } from "./reducer/pageReducer";
import { productReducer } from "./reducer/productReducer";
import { orderReducer } from "./reducer/orderReducer";
import { thunk } from "redux-thunk";
import { combineReducers, createStore, applyMiddleware } from "redux";

const initialState = {};

let reducers = combineReducers({
  pageReducer,
  productReducer,
  orderReducer,
});

const middleware = [thunk];

const reduxStore = createStore(
  reducers,
  initialState,
  applyMiddleware(...middleware)
);

export default reduxStore;
