import React, { useEffect } from "react";
import variantType from "../../utils/variant.type.json";
import VariantsRadioGroup from "./variants.radioGroup";
import VariantsSelectDropDown from "./variants.selectDropDown";
import VariantsInput from "./variants.input";
import VariantsCheckbox from "./variants.checkbox";
import Tooltip from "../tooltip";
import { isNull } from "../../utils/validation";
import VariantsColorGroup from "./variants.colorGroup";
// import CustomColor from "./customColor";
import CustomImage from "./customImage";
import CustomText from "./customText";
import VariantsColorSelect from "./variants.colorSelect";
import VariantsTextarea from "./variants.textarea";
import VariantsCheckboxColor from "./variants.checkboxColor";
import VariantsMultiCheckboxColor from "./variants.multicheckboxColor";
import VariantsSizeSelect from "./variants.sizeSelect";

const Variants = (props) => {
  const setVariants = (name, data) => {
    const vt = {
      [name]: data,
    };
    props.setCurrentVariants(vt);
  };

  useEffect(() => {
    //init currentVariants
    const result = {};
    for (const category in props.init) {
      const instockItem = props.init[category].find(
        (item) => item.instock === 1
      );
      if (instockItem) {
        result[category] = instockItem.name;
      }
    }
    props.setCurrentVariants(result);
  }, []);

  return (
    <div>
      {props.isCustom ? (
        <div>
          {/* <CustomColor original={props.init} setVariants={setVariants} /> */}
          <CustomImage
            setVariants={setVariants}
            init={props.init["Upload Logo"]}
          />
          <CustomText setVariants={setVariants} init={props.init["Add Text"]} />
        </div>
      ) : (
        <></>
      )}
      {Object.keys(props.init).map(
        (key, index) =>
          variantType.settings[key] && (
            <div key={index}>
              <div
                className={`mt-6 relative lg:w-${
                  !isNull(variantType.tooltip[key]) ? "2/3" : "full"
                }`}
              >
                {!isNull(variantType.tooltip[key]) ? (
                  <Tooltip
                    message={variantType.tooltip[key]}
                    x="right"
                    y="top"
                  ></Tooltip>
                ) : (
                  <></>
                )}
                {variantType.settings[key] === "radio" ? (
                  <VariantsRadioGroup
                    name={key}
                    original={props.original[key]}
                    setVariants={setVariants}
                    init={props.init[key]}
                  />
                ) : null}
                {variantType.settings[key] === "colorradio" &&
                !props.isCustom ? (
                  <VariantsColorGroup
                    name={key}
                    original={props.original[key]}
                    setVariants={setVariants}
                    init={props.init[key]}
                  />
                ) : null}
                {variantType.settings[key] === "colorselect" && (
                  <VariantsColorSelect
                    name={key}
                    original={props.original[key]}
                    setVariants={setVariants}
                    init={props.init[key]}
                  />
                )}
                {variantType.settings[key] === "checkboxcolor" && (
                  <VariantsCheckboxColor
                    name={key}
                    original={props.original[key]}
                    setVariants={setVariants}
                    init={props.init[key]}
                  />
                )}
                {variantType.settings[key] === "multicheckboxcolor" && (
                  <VariantsMultiCheckboxColor
                    name={key}
                    original={props.original[key]}
                    setVariants={setVariants}
                    init={props.init[key]}
                  />
                )}
                {variantType.settings[key] === "select" ? (
                  <VariantsSelectDropDown
                    name={key}
                    original={props.original[key]}
                    setVariants={setVariants}
                    init={props.init[key]}
                  />
                ) : null}
                {variantType.settings[key] === "sizeselect" ? (
                  <VariantsSizeSelect
                    name={key}
                    original={props.original[key]}
                    setVariants={setVariants}
                    init={props.init[key]}
                  />
                ) : null}
                {variantType.settings[key].includes("input") ? (
                  <VariantsInput
                    name={key}
                    setVariants={setVariants}
                    type={variantType.settings[key].split("||")[1]}
                    init={props.init[key]}
                  />
                ) : null}
                {variantType.settings[key].includes("textarea") ? (
                  <VariantsTextarea
                    name={key}
                    setVariants={setVariants}
                    type={variantType.settings[key].split("||")[1]}
                    init={props.init[key]}
                    productName={props.productName}
                  />
                ) : null}
                {variantType.settings[key] === "switch" ? (
                  <VariantsCheckbox
                    name={key}
                    setVariants={setVariants}
                    init={props.init[key]}
                  />
                ) : null}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default Variants;
