import {
  CATEGORIES_INIT,
  CATEGORIES_REFRESH,
  PRODUCT_SIZE_CHANGE,
} from "../actions/types";

const initialState = {
  categories: [],
  refresh: true,
  size: 100,
};

export const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CATEGORIES_INIT:
      return {
        ...state,
        categories: payload,
      };
    case CATEGORIES_REFRESH:
      return {
        ...state,
        refresh: payload,
      };
    case PRODUCT_SIZE_CHANGE:
      return {
        ...state,
        size: payload,
      };
    default:
      return state;
  }
};
