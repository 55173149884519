export const creareOrderEmail = (payment, cart) => {
  let emailTemplate = `
    <!DOCTYPE html>
   <html lang="en">
 <head>
   <meta charset="UTF-8">
   <meta name="viewport" content="width=device-width, initial-scale=1.0">
   <title>Order Confirmation</title>
   <style>
        body {
          font-family: system-ui, sans-serif;
          margin: 0;
          padding: 0;
          background-color: #f4f4f4;
        }
        .container {
          max-width: 1000px;
          margin: 40px auto;
          padding: 40px;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .section{padding: 2em 0;}
        .grid{display:grid !important;grid-template-columns: 1fr 1fr;grid-gap: 10px;}
        .flex {display:flex;gap:5px;}
        h2,h3{font-weight:bold;font-size: 1em !important;}
        hr{border-color:black;}

        @media screen and (max-width: 767px) {
          .grid{display:block  !important;}
        }
    </style>
  </head>
<body>
<div class="container">
<div class="flex"><h2>COMPANY/EVENT NAME:</h2><p>${
    payment.shippingAddress.company
  }</p></div>
  <div class="section grid">
          <div>
            <div class="flex"><h3>FIRST NAME:</h3><p>${
              payment.firstName
            }</p></div>
            <div class="flex"><h3>LAST NAME:</h3><p>${
              payment.lastName
            }</p></div>
            <div class="flex"><h3>EMAIL:</h3><p>${payment.email}</p></div>
            <div class="flex"><h3>PHONE:</h3><p>${payment.phone}</p></div>
            <div class="flex"><h3>ALTPHONE:</h3><p>${payment.altPhone}</p></div>
          </div>
          <div>
            <h3>SHIPPING ADDRESS:</h3>
            ${payment.shippingAddress.address}<br/> ${
    payment.shippingAddress.city
  }<br/> ${payment.shippingAddress.code}<br/>
            <b>Residential:</b> ${payment.residential === 0 ? "No" : "Yes"}
            <br/><br/>
            <h3>BILLING ADDRESS:</h3>
            ${payment.billingAddress.company}<br/>
            ${payment.billingAddress.address}<br/> ${
    payment.billingAddress.city
  }<br/> ${payment.billingAddress.code}
          </div>
  </div>
  <hr/>
  <div class="section grid">
    <div>
      <div class="flex"><h3>EVENT DATE:</h3><p>${
        payment.dateEvent.split("T")[0]
      }</p></div>
    </div>
    <div>
      <div class="flex"><h3>IN HAND BY:</h3><p>${
        payment.dateHand.split("T")[0]
      }</p></div>
    </div>
  </div>
  <hr/>
  `;
  let productTemplate = cart.products.map((product, index) => {
    const dependenciesTemplate = product.dependencies
      .filter((dep) => dep.quantity !== 0)
      .map(
        (dep, i) =>
          `<div class="flex"><h3>${dep.name}:</h3><p>${dep.quantity}</p></div>`
      );

    const variantsTemplate = Object.entries(product.variants).map(
      ([key, value]) =>
        `<div class="flex"><h3 style="text-transform: uppercase">${
          product.variants[key].length > 0 ? `${key} : ` : ""
        }</h3> ${
          Array.isArray(value)
            ? `${value.map(
                (va) =>
                  `<ul>
                            ${
                              va.image
                                ? `<li><a href ="${va.image}" target='_blank' rel="noreferrer" style='color:blue;text-decoration:underline'>File Link</a></li>`
                                : `<li>${va.text}</li>`
                            }
                            <li>${va.position}</li>
                            ${
                              va.colour !== undefined
                                ? `<li>${va.colour}</li>`
                                : ""
                            }
                          </ul>`
              )}`
            : value === "false"
            ? "No"
            : value === "true"
            ? "Yes"
            : value
        }</div>`
    );

    return `<div class='section' id='product-${index}'>
        <div class="flex"><h3>TYPE OF BIBS: </h3><p>${product.name}</p></div>
        <div class="flex"><h3>TOTAL NUMBER OF BIBS: </h3><p>${
          product.quantity
        }</p></div>
        ${variantsTemplate.join("")}
        <br/>
        ${dependenciesTemplate.join("")}
    </div>
    <hr/>
       `;
  });

  emailTemplate += `${productTemplate.join("")} 
    <div class="section">
      <h3>ORDER INSTRUCTIONS:</h3>
      <p>${payment.additionalInstructions}</p>
    </div>
    <div class="section grid">
      <div>
        <h3>CREDIT CARD INFORMATION</h3>
        Type: ${payment.cardType}
        <br/>
        Credit card #: XXXX XXXX XXXX ${payment.cardNo.split(" ")[3]}
        <br/>
        Expiry: ${payment.cardDate}
        <br/><br/>
        <h3>GROUND SHIPPING QUOTE</h3>
        $${Number(payment.shippingFee).toFixed(2)}
      </div>
      <div>
        <h3>PRICING SUMMARY</h3>`;

  const pricingSummaryTemplate = cart.products.map((product, index) => {
    const dependenciesPriceTemplate = product.dependencies
      .filter((dep) => dep.quantity !== 0)
      .map(
        (dep, i) =>
          `<div class="flex" key="dep-"${i}>${dep.name} ($${Number(
            dep.price
          ).toFixed(2)}) x ${dep.quantity} = $${Number(dep.total).toFixed(
            2
          )}</div>`
      );

    return `
      <div id='product-price-${index}'>
          <div class="flex">${product.name} ($${Number(product.price).toFixed(
      2
    )}) x ${product.quantity} = $${Number(product.total).toFixed(2)}</div>
          ${dependenciesPriceTemplate.join("")}
      </div>
         `;
  });

  emailTemplate += ` ${pricingSummaryTemplate.join("")}
        <br/>
        <div class="flex"><h3>Total:</h3>$${Number(payment.subtotal).toFixed(
          2
        )}<br/></div>
        <div class="flex"><h3>Taxes (${
          Number(payment.taxRate) * 100
        }%):</h3>$${Number(payment.tax).toFixed(2)}<br/></div>
        <div class="flex"><h3>Grand Total:</h3>$${Number(
          payment.grandTotal
        ).toFixed(2)}</div>
      </div>
      
    </div>
  </div>
  </body>
    `;

  return emailTemplate;
};

export const creareQuoteEmail = (payment, cart) => {
  let emailTemplate = `
    <!DOCTYPE html>
   <html lang="en">
 <head>
   <meta charset="UTF-8">
   <meta name="viewport" content="width=device-width, initial-scale=1.0">
   <title>Order Confirmation</title>
   <style>
        body {
          font-family: system-ui, sans-serif;
          margin: 0;
          padding: 0;
          background-color: #f4f4f4;
        }
        .container {
          max-width: 1000px;
          margin: 40px auto;
          padding: 40px;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .section{padding: 2em 0;}
        .grid{display:grid !important;grid-template-columns: 1fr 1fr;grid-gap: 10px;}
        .flex {display:flex;gap:5px;}
        h2,h3{font-weight:bold;font-size: 1em !important;}
        hr{border-color:black;}

        @media screen and (max-width: 767px) {
          .grid{display:block  !important;}
        }
    </style>
  </head>
<body>
<div class="container">
<div class="flex"><h2>COMPANY/EVENT NAME:</h2><p>${payment.shippingAddress.company}</p></div>
  <div class="section grid">
          <div>
            <div class="flex"><h3>FIRST NAME:</h3><p>${payment.firstName}</p></div>
            <div class="flex"><h3>LAST NAME:</h3><p>${payment.lastName}</p></div>
            <div class="flex"><h3>EMAIL:</h3><p>${payment.email}</p></div>
            <div class="flex"><h3>PHONE:</h3><p>${payment.phone}</p></div>
            <div class="flex"><h3>ALTPHONE:</h3><p>${payment.altPhone}</p></div>
          </div>
          <div>
            <h3>SHIPPING ADDRESS:</h3>
            ${payment.shippingAddress.address}<br/> ${payment.shippingAddress.city}<br/> ${payment.shippingAddress.code}<br/>
          </div>
  </div>
  <hr/>
  <hr/>
  `;
  let productTemplate = cart.products.map((product, index) => {
    const dependenciesTemplate = product.dependencies
      .filter((dep) => dep.quantity !== 0)
      .map(
        (dep, i) =>
          `<div class="flex"><h3>${dep.name}:</h3><p>${dep.quantity}</p></div>`
      );

    const variantsTemplate = Object.entries(product.variants).map(
      ([key, value]) =>
        `<div class="flex"><h3 style="text-transform: uppercase">${
          product.variants[key].length > 0 ? `${key} : ` : ""
        }</h3> ${
          Array.isArray(value)
            ? `${value.map(
                (va) =>
                  `<ul>
                            ${
                              va.image
                                ? `<li><a href ="${va.image}" target='_blank' rel="noreferrer" style='color:blue;text-decoration:underline'>File Link</a></li>`
                                : `<li>${va.text}</li>`
                            }
                            <li>${va.position}</li>
                            ${
                              va.colour !== undefined
                                ? `<li>${va.colour}</li>`
                                : ""
                            }
                          </ul>`
              )}`
            : value === "false"
            ? "No"
            : value === "true"
            ? "Yes"
            : value
        }</div>`
    );

    return `<div class='section' id='product-${index}'>
        <div class="flex"><h3>TYPE OF BIBS: </h3><p>${product.name}</p></div>
        <div class="flex"><h3>TOTAL NUMBER OF BIBS: </h3><p>${
          product.quantity
        }</p></div>
        ${variantsTemplate.join("")}
        <br/>
        ${dependenciesTemplate.join("")}
    </div>
    <hr/>
       `;
  });

  emailTemplate += `${productTemplate.join("")} 
    <div class="section">
      <h3>ORDER INSTRUCTIONS:</h3>
      <p>${payment.additionalInstructions}</p>
    </div>
  </div>
  </body>
    `;

  return emailTemplate;
};
