import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

const CarouselBanner = () => {
  const navigate = useNavigate();
  const importAll = (r) => {
    return r.keys().map(r);
  };

  const images = importAll(
    require.context("../images/banners", false, /\.(jpg|jpeg|png)$/)
  );

  const settings = {
    className: "center",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderRef = React.createRef();

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div>
      <div className="relative">
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <div key={index} className="cursor-pointer flex items-center">
              <div
                className="slick-img"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          ))}
        </Slider>
        <div className="banner-content">
          <div className="banner-content-inner">
            <h2 className="text-2xl font-bold text-indigo-500 pb-2">
              Custom Race Bibs for Any Event!
            </h2>
            <p>
              Running Count has been manufacturing Tyvek race numbers for
              running, cycling and sporting events and shipping globally since
              1981. High quality products • Fast turnaround • Excellent customer
              service
            </p>
            <div className="w-fit rounded-md px-3 py-1 leading-6 bg-indigo-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20 cursor-pointer text-center mt-4">
              <div
                onClick={() => navigate("/products")}
                className="font-semibold text-white w-fit"
              >
                <span aria-hidden="true" />
                Race Bibs Order Now <span aria-hidden="true">&rarr;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ top: "calc(45%)" }} className="absolute w-full text-center">
        <button
          onClick={goToPrev}
          className="text-white text-2xl p-2 absolute left-0 hover:opacity-100 hover:text-white focus:outline-none"
        >
          <ChevronLeftIcon className="h-12 w-12" aria-hidden="true" />
        </button>
        <button
          onClick={goToNext}
          className="text-white text-2xl p-2 absolute right-0 hover:opacity-100 hover:text-white focus:outline-none"
        >
          <ChevronRightIcon className="h-12 w-12" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default CarouselBanner;
