import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  ChevronUpDownIcon,
  CheckIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

const orderStatusOptions = [
  { id: 0, name: "New" },
  { id: 1, name: "Archived" },
  { id: 2, name: "Terminated" },
  { id: 3, name: "Canceled" },
];

const SearchBox = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);

  const handleSearch = () => {};

  return (
    <div className="flex items-center space-x-4">
      {/* Search Box */}
      <input
        type="text"
        value={searchTerm}
        name="search"
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search..."
        className="py-2 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-indigo-400"
      />

      {/* Order Status Dropdown */}
      <Listbox value={selectedStatus} onChange={setSelectedStatus} multiple>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full border border-gray-300 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left sm:text-sm">
            <span className="block truncate">
              {selectedStatus.length > 0
                ? selectedStatus.map((status) => status.name).join(", ")
                : "All Status"}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-auto overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {orderStatusOptions.map((status, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-indigo-100 text-indigo-900" : "text-gray-900"
                    }`
                  }
                  value={status}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {status.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>

      <div
        onClick={() => handleSearch()}
        className="cursor-pointer inline-flex justify-center items-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-white"
      >
        Start Search
        <MagnifyingGlassIcon
          className="h-5 w-5 ml-2 text-white"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

export default SearchBox;
