import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import React, { useState, Fragment, useEffect, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { isNull } from "../../utils/validation";
import { cmykToHex, hexToCmyk } from "../../utils/product";

const positionList = ["Top centre", "Bottom center"];
//product["Add Text"] = [{ text: "", position: "", color:"",inStock: true }];

const CustomText = (props) => {
  const [selectedText, setSelectedText] = useState(props.init ?? []);
  const [ableToAdd, setAbleToAdd] = useState(true);
  const [ableToDelete, setAbleToDelete] = useState(false);
  const firstRender = useRef(true);

  useEffect(() => {
    props.setVariants("Add Text", selectedText);
    if (selectedText.length > 1) {
      setAbleToAdd(false);
    } else {
      setAbleToAdd(true);
    }
    if (selectedText.length > 0) {
      setAbleToDelete(true);
    } else {
      setAbleToDelete(false);
    }
  }, [selectedText]);

  useEffect(() => {
    //init already had variants when editing product
    initVariants();
  }, []);

  const initVariants = () => {
    if (props.init && props.init.length > 0 && firstRender.current) {
      props.init.map((v, i) => {
        addText(i, v.text);
        // handleTextChange(v.text, i);
        handleColorChange(cmykToHex(v.colour), i);
        handlePositionChange(v.position, i);
      });
      firstRender.current = false;
    }
  };

  const handlePositionChange = (value, index) => {
    const updatedPosition = [...selectedText];
    updatedPosition[index].position = value;
    setSelectedText(updatedPosition);
    // const poa = value.split(" ");
    // const txtElemnt = document.getElementById(`added_txt_${index}`);
    // txtElemnt.classList.remove("top-36", "bottom-36");
    // txtElemnt.classList.add(
    //   `${poa[0].toLowerCase()}-36`,
    //   `${poa[1].toLowerCase()}-36`
    // );
  };

  const handleTextChange = async (value, index) => {
    const updatedText = [...selectedText];
    updatedText[index].text = value;
    setSelectedText(updatedText);
    // const txtElemnt = document.getElementById(`added_txt_${index}`);
    // txtElemnt.innerText = value;
  };

  const handleColorChange = async (value, index) => {
    const updatedText = [...selectedText];
    updatedText[index].colour = hexToCmyk(value);
    setSelectedText(updatedText);
    // const txtElemnt = document.getElementById(`added_txt_${index}`);
    // txtElemnt.style.color = value;
  };

  const addText = (index, defaultTxt) => {
    // const textContainer = document.getElementById("custom_full");
    // // Create an <p> element
    // const txtElement = document.createElement("p");
    // txtElement.innerText = defaultTxt;
    // txtElement.className = "w-3/4 z-11 absolute top-36 text-xl";
    // txtElement.id = `added_txt_${index}`;
    // textContainer.appendChild(txtElement);
  };

  const removeText = (index) => {
    // const txtElement = document.getElementById(`added_txt_${index}`);
    // if (txtElement) {
    //   txtElement.remove();
    // }
  };

  const handleAddButton = () => {
    addText(selectedText.length, "Your Text Here");
    setSelectedText([
      ...selectedText,
      {
        text: "",
        position: "Top centre",
        colour: "[0,0,0,100]",
      },
    ]);
  };

  const handleDeleteButton = () => {
    removeText(selectedText.length - 1);
    let current = [...selectedText];
    current.pop();
    setSelectedText(current);
  };

  const checkColorFormatToDisplay = (c) => {
    const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
    if (hexRegex.test(c)) {
      //its hex, no need to convert
      return c;
    } else {
      //its cymk, need to convert to hex for displaying
      return cmykToHex(c);
    }
  };

  const uploadButtons = selectedText.map((dt, index) => (
    <div
      key={index}
      className="flex flex-row mb-4 items-center gap-2 justify-center"
    >
      <div className="w-1/3">
        <input
          placeholder="Your Text Here"
          type="text"
          onChange={(e) => handleTextChange(e.target.value, index)}
          value={selectedText[index].text}
          className="w-full rounded-md border border-gray-200 px-2 py-2 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
      <div className="w-1/2">
        <Listbox
          value={selectedText[index].position}
          onChange={(value) => handlePositionChange(value, index)}
        >
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full border border-gray-300 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left sm:text-sm">
              <span className="block truncate">
                {!isNull(selectedText[index].position)
                  ? selectedText[index].position
                  : "Select postion"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm border border-gray-300">
                {positionList.map((data, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-2 pr-4 ${
                        active
                          ? "bg-indigo-100 text-indigo-900"
                          : "text-gray-900"
                      }`
                    }
                    value={data}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          } `}
                        >
                          {data}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="w-1/4 flex justify-center">
        <input
          type="color"
          value={checkColorFormatToDisplay(selectedText[index].colour)}
          onChange={(e) => handleColorChange(e.target.value, index)}
          className="h-10 w-10 cursor-pointer"
        />
        {/* <SketchPicker
          color={selectedText[index].color}
          onChange={(color) => handleColorChange(color.cmyk, index)}
        /> */}
      </div>
    </div>
  ));

  return (
    <div className="mt-6">
      <h3 className="text-sm font-medium capitalize">Add Text</h3>

      <div className={`mt-2 bg-gray-200 px-2 py-2`}>
        {uploadButtons}
        <b
          onClick={handleAddButton}
          className={`cursor-pointer text-indigo-600 flex items-center ${
            !ableToAdd ? "hidden" : ""
          }`}
        >
          <span>
            <PlusIcon className="w-4 h-4" />
          </span>{" "}
          Add Text
        </b>
        <b
          onClick={handleDeleteButton}
          className={`cursor-pointer text-red-600 flex items-center ${
            !ableToDelete ? "hidden" : ""
          }`}
        >
          <span>
            <MinusIcon className="w-4 h-4" />
          </span>{" "}
          Remove a Text
        </b>
      </div>
    </div>
  );
};

export default CustomText;
