import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import React, { useState, Fragment, useEffect, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { isNull } from "../../utils/validation";
import { cmykToHex, hexToCmyk } from "../../utils/product";

const positionList = [
  "Top right",
  "Top left",
  "Top centre",
  "Bottom right",
  "Bottom left",
  "Bottom centre",
];
//"Upload Logo": [{ name: "", position: "",color:"", inStock: true }],

const CustomImage = (props) => {
  const [selectedImages, setSelectedImages] = useState(props.init ?? []);
  const [ableToAdd, setAbleToAdd] = useState(true);
  const [ableToDelete, setAbleToDelete] = useState(false);
  const firstRender = useRef(true);

  useEffect(() => {
    props.setVariants("Upload Logo", selectedImages);
    // if (selectedImages.length > 2) {
    //   setAbleToAdd(false);
    // } else {
    //   setAbleToAdd(true);
    // }
    if (selectedImages.length > 0) {
      setAbleToDelete(true);
    } else {
      setAbleToDelete(false);
    }
  }, [selectedImages]);

  useEffect(() => {
    //init already had variants when editing product
    initVariants();
  }, []);

  const initVariants = () => {
    if (props.init && props.init.length > 0 && firstRender.current) {
      props.init.map((v, i) => {
        addImage(i);
        handleImageChange(v.image, i);
        handlePositionChange(v.position, i);
        // handleColorChange(cmykToHex(v.colour), i);
      });
      firstRender.current = false;
    }
  };

  // const handleColorChange = async (value, index) => {
  //   const updatedImage = [...selectedImages];
  //   updatedImage[index].colour = hexToCmyk(value);
  //   setSelectedImages(updatedImage);
  //   const imgElement = document.getElementById(`uploaded_img_${index}`);
  //   imgElement.style.color = value;
  // };

  const handlePositionChange = (value, index) => {
    const updatedPosition = [...selectedImages];
    updatedPosition[index].position = value;
    setSelectedImages(updatedPosition);
    // const poa = value.split(" ");
    // const imageElemnt = document.getElementById(`uploaded_img_${index}`);
    // imageElemnt.classList.remove("top-4", "bottom-4", "left-4", "right-4");
    // imageElemnt.classList.add(
    //   `${poa[0].toLowerCase()}-4`,
    //   `${poa[1].toLowerCase()}-4`
    // );
  };

  const handleImageChange = async (inputOrUrl, index) => {
    let file, imageUrl;

    // Check the type of inputOrUrl
    if (typeof inputOrUrl === "string") {
      imageUrl = inputOrUrl;
    } else if (inputOrUrl.target && inputOrUrl.target.files) {
      file = inputOrUrl.target.files[0];
    }

    if (file || imageUrl) {
      const reader = new FileReader();
      const updatedImages = [...selectedImages];
      reader.onloadend = () => {
        updatedImages[index].image = imageUrl || file;
        // const imageElement = document.getElementById(`uploaded_img_${index}`);
        // if (imageElement) {
        //   // imageElement.src = reader.result;
        //   updatedImages[index].base64 = reader.result;
        // }
      };
      if (file) {
        reader.readAsDataURL(file);
      }
      setSelectedImages(updatedImages);
    }
  };
  //   let file, imageUrl;

  //   // Check the type of inputOrUrl
  //   if (typeof inputOrUrl === "string") {
  //     imageUrl = inputOrUrl;
  //   } else if (inputOrUrl.target && inputOrUrl.target.files) {
  //     file = inputOrUrl.target.files[0];
  //   }

  //   if (file || imageUrl) {
  //     const reader = new FileReader();
  //     const updatedImages = [...selectedImages];

  //     reader.onloadend = () => {
  //       updatedImages[index].image = imageUrl || file;

  //       if (file && file.type === "application/pdf") {
  //         // Handle PDF file
  //         // imageElement.src = ""; // Clear existing image
  //         const pdfContainer = document.getElementById(
  //           `pdf_container_${index}`
  //         );
  //         pdfContainer.innerHTML = `<iframe src="${reader.result}" width="100%" height="100%"></iframe>`;
  //       } else if (file && file.type === "image/svg+xml") {
  //         // Handle SVG file
  //         // imageElement.src = ""; // Clear existing image
  //         const svgContainer = document.getElementById(
  //           `svg_container_${index}`
  //         );
  //         svgContainer.innerHTML = reader.result;
  //       } else {
  //         // Handle other image types
  //         // imageElement.src = reader.result;
  //         updatedImages[index].base64 = reader.result;
  //       }
  //     };

  //     const imageElement = document.getElementById(`uploaded_img_${index}`);
  //     if (file) {
  //       reader.readAsDataURL(file);
  //     }
  //     if (imageUrl) {
  //       // Handle image URL
  //       updatedImages[index].base64 = imageUrl;
  //       imageElement.src = imageUrl;
  //     }

  //     setSelectedImages(updatedImages);
  //   }
  // };

  const addImage = (index) => {
    // const imageContainer = document.getElementById("custom_full");
    // // Create an <img> element
    // const txtElement = document.createElement("p");
    // txtElement.innerText = "LOGO " + index;
    // txtElement.className = "w-1/4 h-auto z-10 absolute top-4 text-xl font-bold";
    // txtElement.id = `uploaded_img_${index}`;
    // imageContainer.appendChild(txtElement);
    // console.log("add image");
    // const imageContainer = document.getElementById("custom_img");
    // imageContainer.src=
  };

  const removeImage = (index) => {
    // const imgElement = document.getElementById(`uploaded_img_${index}`);
    // if (imgElement) {
    //   imgElement.remove();
    // }
  };

  const handleAddButton = () => {
    addImage(selectedImages.length);
    setSelectedImages([
      ...selectedImages,
      {
        image: "",
        position: "Top centre",
        base64: "",
      },
    ]);
  };

  const handleDeleteButton = () => {
    removeImage(selectedImages.length - 1);
    let current = [...selectedImages];
    current.pop();
    setSelectedImages(current);
  };

  // const checkColorFormatToDisplay = (c) => {
  //   const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  //   if (hexRegex.test(c)) {
  //     //its hex, no need to convert
  //     return c;
  //   } else {
  //     //its cymk, need to convert to hex for displaying
  //     return cmykToHex(c);
  //   }
  // };

  const uploadButtons = selectedImages.map((dt, index) => (
    <div key={index} className="flex flex-row mb-4 items-center">
      <div className="w-1/3">
        <label className="relative bg-white border border-gray-300 p-2.5 rounded-md cursor-pointer text-center text-sm">
          <span>{!ableToDelete ? "Uploaded" : "Browse"}</span>
          <input
            type="file"
            accept=".jpg, .ai, .eps"
            onChange={(e) => handleImageChange(e, index)}
            className="hidden"
          />
        </label>
      </div>
      <div className="w-1/2">
        <Listbox
          value={selectedImages[index].position}
          onChange={(value) => handlePositionChange(value, index)}
        >
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full border border-gray-300 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left sm:text-sm">
              <span className="block truncate">
                {!isNull(selectedImages[index].position)
                  ? selectedImages[index].position
                  : "Select postion"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm border border-gray-300">
                {positionList.map((data, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-2 pr-4 ${
                        active
                          ? "bg-indigo-100 text-indigo-900"
                          : "text-gray-900"
                      }`
                    }
                    value={data}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          } `}
                        >
                          {data}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      {/* <div className="w-1/4 flex justify-center">
        <input
          type="color"
          value={checkColorFormatToDisplay(selectedImages[index].colour)}
          onChange={(e) => handleColorChange(e.target.value, index)}
          className="h-10 w-10 cursor-pointer"
        />
      </div> */}
      {/* <div className="w-1/4 flex justify-center text-sm">
        {!isNull(dt.base64) ? (
          <img
            src={dt.base64}
            alt={`Uploaded Preview ${index + 1}`}
            className="w-8 h-auto"
          />
        ) : (
          <p className="text-sm">No image</p>
        )}
      </div> */}
    </div>
  ));

  return (
    <div className="mt-6">
      <h3 className="text-sm font-medium capitalize">Upload Logo</h3>

      <div className={`mt-2 bg-gray-200 px-2 py-2`}>
        {uploadButtons}
        <b
          onClick={handleAddButton}
          className={`cursor-pointer text-indigo-600 flex items-center ${
            !ableToAdd ? "hidden" : ""
          }`}
        >
          <span>
            <PlusIcon className="w-4 h-4" />
          </span>{" "}
          Add a Logo
        </b>
        <b
          onClick={handleDeleteButton}
          className={`cursor-pointer text-red-600 flex items-center ${
            !ableToDelete ? "hidden" : ""
          }`}
        >
          <span>
            <MinusIcon className="w-4 h-4" />
          </span>{" "}
          Remove a Logo
        </b>
      </div>
    </div>
  );
};

export default CustomImage;
