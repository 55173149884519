import { getCartCookies } from "./cookies";
import priceJS from "./price.json";
import { isNull } from "./validation";

export const cartSubtotalCalculate = (cart) => {
  try {
    const subtotal = cart.products.reduce((acc, item) => {
      const nestedTotal = item.dependencies.reduce(
        (nestedAcc, nestedItem) => nestedAcc + Number(nestedItem.total),
        0
      );
      return acc + Number(item.total) + nestedTotal;
    }, 0);

    return subtotal.toFixed(2);
  } catch (e) {
    return 0;
  }
};

export const cartWeightCalculate = (cart) => {
  try {
    const totalWeight = cart.products.reduce((acc, item) => {
      return acc + Number(item.weight);
    }, 0);
    return totalWeight.toFixed(2);
  } catch (e) {
    return 0;
  }
};

export const cartItemsCount = async (checkoutState) => {
  try {
    let currentCart = await getCartCookies(checkoutState);
    const countP = currentCart.products.reduce(
      (acc, product) => acc + Number(product.quantity),
      0
    );
    const countD = currentCart.products.map((product) => {
      const dependencies = product.dependencies || [];
      const totalAmount = dependencies.reduce((total, dependency) => {
        return total + (dependency.quantity || 0);
      }, 0);

      return totalAmount;
    });

    // Calculate the overall count from the array of total amounts
    const overallCount = countD.reduce((total, count) => total + count, 0);

    return Number(countP) + Number(overallCount);
  } catch (e) {
    return 0;
  }
};

export const cartItemsCatogeryCount = async () => {
  try {
    let currentCart = await getCartCookies("checkout");
    if (currentCart.products) {
      const countP = currentCart.products.length;
      return countP;
    } else {
      return 0;
    }
  } catch (e) {
    console.error("cartItemsCatogeryCount", e);
    return 0;
  }
};

export const getEditCart = async (currentCart, productIndex) => {
  let currentProduct = currentCart.products[productIndex];
  let newVariants = {};
  for (const key in currentProduct.variants) {
    const value = currentProduct.variants[key];

    if (Array.isArray(value)) {
      // Handle arrays like "Add text" and "Upload Logo"
      newVariants[key] = value.map((item) => ({ ...item, inStock: true }));
    } else {
      // Handle other properties
      newVariants[key] = [{ name: value, inStock: true }];
    }
  }
  currentProduct.variants = newVariants;
  return { id: currentCart.id, currentProduct };
};

export const calculateTax = (subtotal, state) => {
  if (!isNull(state)) {
    const stateStr = "tax_" + state;
    let tax = Number(priceJS[stateStr]);

    if (isNull(tax) || isNaN(tax)) {
      tax = Number(priceJS.tax_OTHER);
    }
    return {
      tax: Number(subtotal) * tax,
      total: Number(subtotal) * tax + Number(subtotal),
      taxRate: tax,
    };
  }
  return { tax: false, total: subtotal, taxRate: "" };
};
