import React, { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, UserIcon } from "@heroicons/react/20/solid";
import logo from "../../images/logo.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { categorySet } from "../../redux/apis/pageAPI";
import CartIcon from "./cartIcon";
import tempData from "../../utils/temp.json";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Menu() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectCategory = (name, id) => {
    dispatch(categorySet({ name: name, id: id }));
    navigate(`/products`);
  };

  return (
    <div>
      <header className="bg-white">
        <nav
          className="mx-auto flex max-w-9xl items-center justify-between p-4 lg:px-16"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <div onClick={() => navigate(`/`)}>
              <img
                className="logo-size w-auto cursor-pointer"
                src={logo}
                alt="logo"
              />
            </div>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            <div
              onClick={() => navigate(`/`)}
              className="font-semibold leading-6 cursor-pointer"
            >
              Home
            </div>
            <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-1 font-semibold leading-6 ">
                Products
                <ChevronDownIcon
                  className="h-5 w-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                  {({ close }) => (
                    <div className="p-4">
                      {tempData.categories.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex items-center gap-x-6 rounded-lg p-4 leading-6 hover:bg-gray-50"
                        >
                          <div className="flex-auto cursor-pointer">
                            <div
                              onClick={() => {
                                redirectCategory(item.name, item.id);
                                close();
                              }}
                              className="block font-semibold cursor-pointer"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </Popover>

            <div
              onClick={() => navigate(`/faq`)}
              className="font-semibold leading-6 cursor-pointer"
            >
              FAQ
            </div>
            <div
              onClick={() => navigate(`/contact`)}
              className="font-semibold leading-6 cursor-pointer"
            >
              Contact Us
            </div>
          </Popover.Group>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {/* <a href="/" className="text-sm font-semibold leading-6 mt-0.5">
              <UserIcon className="h-6 w-6" aria-hidden="true" />
            </a> */}
            <CartIcon />
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <div onClick={() => navigate(`/`)} className="cursor-pointer">
                <img className="h-8 w-auto" src={logo} alt="logo" />
              </div>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <div
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50 cursor-pointer"
                    onClick={() => navigate(`/`)}
                  >
                    Home
                  </div>
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                          Products
                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              "h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {[...tempData.categories].map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              onClick={() =>
                                redirectCategory(item.name, item.id)
                              }
                              className="block rounded-lg py-2 pl-6 pr-3 font-semibold leading-7 hover:bg-gray-50"
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <div
                    onClick={() => navigate(`/faq`)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50 cursor-pointer"
                  >
                    FAQ
                  </div>
                  <div
                    onClick={() => navigate(`/contact`)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50 cursor-pointer"
                  >
                    Contact Us
                  </div>
                </div>
                <div className="py-6">
                  <div className="flex flex-1 justify-between">
                    {/* <a
                      href="/"
                      className="text-sm font-semibold leading-6 mt-0.5"
                    >
                      <UserIcon className="h-6 w-6" aria-hidden="true" />
                    </a> */}
                    <CartIcon />
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <hr />
    </div>
  );
}
