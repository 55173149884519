import { hasCookies } from "./cookies";
import { imageUpload } from "../redux/apis/productAPI";
import { Navigate } from "react-router";

export const isNull = (str) => {
  if (str === "" || str === null || str === undefined) {
    return true;
  }
  return false;
};

export const isObject = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

export const isValidCheckoutPage = (checkoutState) => {
  try {
    const searchParams = window.location.search;
    const id = searchParams.split("=")[1];
    const valid = hasCookies(id, checkoutState);
    if (!valid) {
      Navigate("/404");
    } else {
      return true;
    }
  } catch (e) {
    console.error("CHeckout page validation error:", e);
    Navigate("/404");
  }
};

export const validateForm = (data, ignoreList) => {
  if (data.dateEvent) {
    const isDateHandGreaterThanDateEvent =
      new Date(data.dateHand) > new Date(data.dateEvent);
    if (isDateHandGreaterThanDateEvent) {
      return "In Hand Date should be smaller or equal to Event Date";
    }
  }
  const checkNested = (data, path) => {
    for (const key in data) {
      const currentPath = path ? `${path}.${key}` : key;
      if (typeof data[key] === "object" && data[key] !== null) {
        const nestedResult = checkNested(data[key], currentPath);
        if (nestedResult) {
          return nestedResult; // Return information about the nested empty field
        }
      } else if (
        !ignoreList.includes(key) &&
        (data[key] === null ||
          data[key] === undefined ||
          (typeof data[key] === "string" && data[key].trim() === ""))
      ) {
        return { hasEmptyField: true, emptyField: currentPath }; // Return information about the empty field
      }
    }
    return null; // No empty field found
  };

  const nullField = checkNested(data, null);

  if (nullField && nullField.hasEmptyField === true) {
    return nullField.emptyField + " field is invalid";
  } else {
    return true;
  }
};

export const ValidateDashboardLogin = (data) => {
  if (data.email !== "123" || data.password !== "123") {
    return false;
  }
  return true;
};

export const isValidBibStartandEnd = (start, end) => {
  if (isNull(start) || isNull(end)) {
    return false;
  }
  if (end > 0 && Number(end) - Number(start) > 0) {
    return true;
  }
  return false;
};

export const checkCustomImage = async (v) => {
  let validImage = true;
  await Promise.all(
    v.map(async (item) => {
      if (item["Upload Logo"] && Array.isArray(item["Upload Logo"])) {
        await Promise.all(
          item["Upload Logo"].map(async (vi) => {
            if (vi.image) {
              if (vi.image instanceof File) {
                vi.image = await imageUpload(vi.image);
              }
              if (vi.base64) {
                delete vi.base64;
              }
            } else {
              validImage = false;
            }
          })
        );
      }
    })
  );
  return validImage;
};

export const checkCustomText = async (v) => {
  let validText = true;
  v.map(async (item) => {
    if (item["Add Text"] && Array.isArray(item["Add Text"])) {
      item["Add Text"].map(async (vi) => {
        if (vi.text) {
          if (vi.text === "" || vi.text === undefined) {
            validText = false;
          }
        } else {
          validText = false;
        }
      });
    }
  });
  return validText;
};

export const isValidInteger = (value, max) => {
  if (value < 0 || value > (max ?? 999999)) {
    return false;
  }
  return true;
};

export const isValidAddress = (address) => {
  for (const key in address) {
    if (address.hasOwnProperty(key)) {
      const value = address[key];

      if (isNull(value)) {
        return false;
      }
    }
  }

  return true;
};

export const isValidCard = (cardNo) => {
  try {
    if (cardNo.length < 16) {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};
