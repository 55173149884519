import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";

const VariantsColorGroup = (props) => {
  const [selected, setSelected] = useState(props.init[0]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleChange = (value) => {
    setSelected(value);
    props.setVariants(props.name, value.name);
  };

  return (
    <div id={`v-${props.name.replace(/\s+/g, "-")}`}>
      <h3 className="text-sm font-medium capitalize">{props.name}</h3>

      <RadioGroup value={selected} onChange={handleChange} className="mt-4">
        <div className="flex items-center space-x-3 ml-2">
          {props.original.map((data) => (
            <RadioGroup.Option
              key={data.name}
              value={data}
              className={({ active, checked }) =>
                classNames(
                  active && checked ? "ring ring-offset-1" : "",
                  !active && checked ? "ring-2" : "",
                  data.name === "NONE" ? "disabled" : "",
                  "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                )
              }
            >
              <RadioGroup.Label as="span" className="sr-only">
                {data.name}
              </RadioGroup.Label>
              <span
                aria-hidden="true"
                className={classNames(
                  data.class,
                  "h-8 w-8 rounded-full border border-black border-opacity-10"
                )}
                style={{ background: data.name }}
              />
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default VariantsColorGroup;
