import React from "react";
import "../../styles/header.css";
import { useNavigate } from "react-router-dom";
import tempData from "../../utils/temp.json";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="mx-auto p-6 lg:px-16 bg-gray-100">
      <div className="max-w-9xl px-10 mx-auto flex justify-between item-start">
        {/* Column 1: Shop With Category */}
        <div className="flex-1 pr-8">
          <h2 className="font-semibold leading-6 mb-4">Products</h2>
          <ul>
            {tempData.categories.map((mn, index) => (
              <li key={mn.name + index}>
                <div
                  className="cursor-pointer text-gray-600"
                  onClick={() => navigate(`/products`)}
                >
                  {mn.name}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Column 2: Quick Links */}
        <div className="flex-1 pr-8">
          <h2 className="font-semibold leading-6 mb-4">Quick Links</h2>
          <ul>
            <li>
              <div
                className="cursor-pointer text-gray-600"
                onClick={() => navigate(`/`)}
              >
                Payment
              </div>
            </li>
            <li>
              <div
                className="cursor-pointer text-gray-600"
                onClick={() => navigate(`/about`)}
              >
                About us
              </div>
            </li>
            <li>
              <div
                className="cursor-pointer text-gray-600"
                onClick={() => navigate(`/contact`)}
              >
                Contact us
              </div>
            </li>
            <li>
              <div
                className="cursor-pointer text-gray-600"
                onClick={() => navigate(`/faq`)}
              >
                FAQ
              </div>
            </li>
          </ul>
        </div>

        {/* Column 3: Contact Us */}
        <div className="flex-1">
          <h2 className="font-semibold leading-6 mb-4">Contact Us</h2>
          <p className="text-gray-600">Toll free: 1.866.542.9099</p>
          <p className="text-gray-600">Local: 519.253.9099</p>
          <p className="text-gray-600">Email: sales@runningcount.com</p>
          {/* Social Media Icons */}
          {/* <div className="flex mt-4">
            <a href="/" className="mr-4">
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0 0 20 3.92a8.19 8.19 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743 11.65 11.65 0 0 1-8.457-4.287 4.106 4.106 0 0 0 1.27 5.477A4.073 4.073 0 0 1 .8 7.713v.052a4.105 4.105 0 0 0 3.292 4.022 4.095 4.095 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.233 8.233 0 0 1 0 16.407a11.615 11.615 0 0 0 6.29 1.84" />
              </svg>
            </a>
          </div> */}
        </div>
      </div>
      <hr className="mt-4" />
      <p className="text-center text-sm mt-4 text-gray-500">
        Copyright © 2024 RunningCount. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
