import React, { useEffect, useRef, useState } from "react";
import QuantityButton from "../../components/product/quanyityButton";
import Layout from "../Layout";
import Variants from "../../components/product/variants";
import { useDispatch, useSelector } from "react-redux";
import { cartUpdate, cartUpdateProduct } from "../../redux/apis/orderAPI";
import { customProductGet } from "../../redux/apis/productAPI";
import { calculateTotal, listenBibSnE } from "../../utils/product";
import { findObjectInArray } from "../../utils/object";
import {
  checkCustomImage,
  isNull,
  isValidBibStartandEnd,
} from "../../utils/validation";
import PriceRangeTable from "../../components/product/priceRangeTable";
import { useNavigate, useLocation } from "react-router";
import ProductDetailsSkeleton from "../../components/skeleton/productDetails_Skeleton";
import tearOffImg from "../../images/tearoff.jpg";

export default function EditCustom() {
  const navigate = useNavigate();
  const location = useLocation();
  const productSizePercentage = useSelector(
    (state) => state.productReducer.size
  );
  const currentProduct = location.state.currentProduct;
  const currentIndex = location.state.currentIndex;
  const currentCartID = location.state.currentCartID;
  const currentCart = location.state.currentCart;
  const [product, setProduct] = useState({});
  const [mainImage, setMainImage] = useState("");
  const [errorMessage, setErrorMessage] = useState({ enable: false, msg: "" });
  const pending = useRef(true);
  const dispatch = useDispatch();
  const numberChange = useRef(false);
  // cart detail
  const [v, setV] = useState([]);
  const [q, setQ] = useState(1);
  const [totalQ, setTotalQ] = useState(0);
  const [price, setPrice] = useState(0);

  //custom image change
  const baseUrl = process.env.PUBLIC_URL;
  const imgFolderPre = baseUrl + "/custom_products/";
  const [imgFolder, setImgFolder] = useState("bibs");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productData = await customProductGet();
        setProduct(productData);
        const updatedVariants = Object.keys(currentProduct.variants).reduce(
          (acc, variantKey) => {
            const variantOptions = currentProduct.variants[variantKey];
            const firstInStockOption = variantOptions.find(
              (option) => option.inStock
            );
            if (firstInStockOption) {
              if (variantKey === "Upload Logo" || variantKey === "Add Text") {
                // Handle "Upload Logo" and "Add Text" differently, pushing an array
                acc.push({
                  [variantKey]: variantOptions,
                });
              } else {
                // For other variants, push the name property directly
                acc.push({ [variantKey]: firstInStockOption.name });
              }
            }
            return acc;
          },
          []
        );

        setV(updatedVariants);
        setMainImage(productData.image[0].src);
        //custom image
        const regex = /tearoff/i;
        const regex_bike = /bike/i;
        setImgFolder(
          `${imgFolderPre}${
            regex.test(productData.name)
              ? "tearoff_bibs"
              : regex_bike.test(productData.name)
              ? "bike"
              : "bibs"
          }`
        );
        setQ(
          isNaN(Number(currentProduct.variants["How Many Sets?"][0].name))
            ? 0
            : Number(currentProduct.variants["How Many Sets?"][0].name)
        );
        pending.current = false;
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchData();
  }, []);

  //listening on input
  useEffect(() => {
    listenBibSnE(handleNumberChange, numberChange.current);
  }, []);
  const handleNumberChange = () => {
    numberChange.current = true;
  };

  //handle price change when variants change, only happens when product type is bib number
  useEffect(() => {
    if (!pending.current) {
      let newQuantity = q;
      if (product.productType === "custom") {
        newQuantity =
          Number(findObjectInArray(v, "End Number") ?? 0) -
          Number(findObjectInArray(v, "Start Number") ?? 0);

        //check start and end number
        if (newQuantity <= 0 && numberChange.current) {
          newQuantity = 0;
          setErrorMessage({
            enable: true,
            msg: "End Number should be greater than Start Number",
          });
        } else {
          setErrorMessage({ enable: false, msg: "" });
          newQuantity += 1;
          newQuantity = newQuantity * q;
        }
      }
      handlePriceChange(newQuantity);
    }
  }, [v, q]);

  const handleQuantityBox = (quan) => {
    setQ(quan);
    setCurrentVariants({
      "How Many Sets?": quan,
    });
  };

  const updateCart = async (e) => {
    if (e) e.preventDefault();
    if (totalQ < 1) {
      setErrorMessage({
        enable: true,
        msg: "End Number should be greater than Start Number and cannot be 0",
      });
      return;
    }
    if (findObjectInArray(v, "Print Colour") === ",") {
      setErrorMessage({
        enable: true,
        msg: "Must choose one print colour",
      });
      return;
    }
    if (findObjectInArray(v, "End Number")) {
      if (
        !isValidBibStartandEnd(
          findObjectInArray(v, "Start Number"),
          findObjectInArray(v, "End Number")
        )
      ) {
        setErrorMessage({
          enable: true,
          msg: "End Number should be greater than Start Number and cannot be 0",
        });
        return;
      }
    }
    //update upload logo object
    const validImage = await checkCustomImage(v);
    if (!validImage) {
      setErrorMessage({
        enable: true,
        msg: "No File uploaded!",
      });
      return;
    }
    //regular
    let cartValid = { valid: false, data: "" };
    cartValid = await cartUpdate(product, v, totalQ, price, currentIndex);
    if (cartValid.valid) {
      setErrorMessage({ enable: false, msg: "" });
      await cartUpdateProduct(
        currentCart,
        cartValid.data,
        currentIndex,
        "update"
      );
      navigate(`/cart?id=${currentCartID}`);
    } else {
      setErrorMessage({
        enable: true,
        msg: cartValid.data + " field is invalid",
      });
    }
  };

  const handlePriceChange = (quantity) => {
    let p = calculateTotal(
      quantity,
      product.priceTable,
      v,
      product.setupFee,
      imgFolder
    );
    setPrice(p.total);
    setMainImage(p.customImgSRC);
    setTotalQ(quantity);
  };

  const setCurrentVariants = (data) => {
    const updatedVariants = [...v];
    Object.keys(data).forEach((key) => {
      const variantIndex = updatedVariants.findIndex(
        (variant) => key in variant
      );
      let newValue = data[key];
      if (typeof newValue !== "object") {
        newValue = String(newValue);
      }
      if (variantIndex !== -1) {
        updatedVariants[variantIndex] = {
          ...updatedVariants[variantIndex],
          [key]: newValue,
        };
      } else {
        updatedVariants.push({ [key]: newValue });
      }
    });
    setV(updatedVariants);
  };

  return (
    <Layout className="bg-white">
      {!pending.current ? (
        <div>
          <div className="mx-auto sm:px-6 grid lg:grid-cols-2 gap-x-8">
            {/*LEFT*/}
            <div className="productPanel1 lg:py-10 lg:col-span-1 lg:pb-16 lg:pr-8 lg:pt-6">
              <div className="hidden lg:block">
                <div className="flex justify-between bk-white">
                  <h1 className="text-2xl font-bold tracking-tight sm:text-3xl">
                    {product.name}
                  </h1>
                  <p className="text-3xl tracking-tight">${price}</p>
                </div>
              </div>

              <div className="block aspect-h-4 aspect-w-3 mt-4">
                <div className=" aspect-h-4 aspect-w-3 rounded-lg">
                  <img
                    id="custom_img"
                    src={mainImage}
                    className="w-full object-cover object-center"
                    alt={product.name}
                  />
                </div>
                {/* <div
                  className="relative"
                  style={{ paddingTop: productSizePercentage + "%" }}
                >
                  <div
                    id="custom_full"
                    className="absolute inset-0 border border-black flex items-center justify-center text-center"
                  >
                    <h1
                      id="custom_strip"
                      className="font-bold custom-font w-full"
                    >
                      100
                    </h1>
                    <img
                      id="tearoff"
                      src={tearOffImg}
                      className="hidden absolute bottom-0"
                    />
                  </div>
                </div> */}
              </div>
              <div className="mt-10">
                <h3 className="text-sm font-medium">Details</h3>
                <div className="mt-4">
                  <ul className="list-disc space-y-2 pl-4 text-sm">
                    {!isNull(product.highlights) &&
                      product.highlights.map((highlight) => (
                        <li key={highlight} className="text-gray-400">
                          <span className="text-gray-600">{highlight}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <PriceRangeTable data={product.priceTable} />
            </div>
            {/*RIGHT*/}
            <div className="productPanel2 mt-4 lg:mt-0 lg:col-span-1 lg:pr-8 ">
              {/*lg:overflow-y-scroll lg:h-screen*/}
              <div className="lg:hidden sm:block">
                <div className="flex justify-between bk-white">
                  <h1 className="text-2xl font-bold tracking-tight sm:text-3xl">
                    {product.name}
                  </h1>
                  {product.subTitle && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product.subTitle,
                      }}
                    ></div>
                  )}
                  <p className="text-3xl tracking-tight">${price}</p>
                </div>
              </div>
              <form className="mt-10">
                <Variants
                  original={product.variants}
                  init={currentProduct.variants}
                  setCurrentVariants={setCurrentVariants}
                  isCustom={true}
                  productName={product.name}
                />

                <div className="mt-6">
                  <h3 className="text-sm font-medium">How Many Sets?</h3>
                  <div className="mt-2 lg:w-1/2">
                    <QuantityButton
                      qua={q}
                      setQ={handleQuantityBox}
                      min={product.unitSet}
                      unitSet={product.unitSet}
                      max={product.maxThreshold}
                    />
                  </div>
                  <p className="mt-4 font-bold">(Total Quantity: {totalQ})</p>
                </div>
                <div className="mt-8">
                  {errorMessage.enable ? (
                    <div
                      className="bg-red-100 border border-red-400 text-red-700 px-4 rounded relative"
                      role="alert"
                    >
                      <strong className="font-bold">Error! </strong>
                      <span className="block sm:inline">
                        {errorMessage.msg}
                      </span>
                    </div>
                  ) : null}
                  <button
                    onClick={(event) => updateCart(event)}
                    className="mt-4 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-white hover:text-indigo-500 hover:border-indigo-500 focus:outline-none focus:ring-indigo-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Update Cart
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <ProductDetailsSkeleton />
      )}
    </Layout>
  );
}
