import { v4 as uuidv4 } from "uuid";
import { isNull, isObject } from "./validation";
import {
  createCart,
  deleteCart,
  getCart,
  updateCart,
} from "../redux/apis/orderAPI";

const cookiesName = {
  checkout: "runningcount_userCookies",
  quote: "runningcount_quoteCookies",
};

const setCookie = async (userID, checkoutState, cartInfo, handler) => {
  const now = new Date();
  let expirationTime = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  expirationTime = expirationTime.toUTCString();
  document.cookie = `${cookiesName[checkoutState]}=${userID}; expires=${expirationTime}; path=/`;
  if (handler === "update") {
    await updateCart(userID, cartInfo);
  } else {
    await createCart(userID, cartInfo);
  }
};

export const refreshCookiesTime = (data) => {
  let storedCookies = getCookie("checkout");
  if (!isNull(storedCookies)) {
    // setCookie(storedCookies.userId, "checkout", data);
    return true;
  } else {
    return false;
  }
};

const getCookie = (checkoutState) => {
  const name = `${cookiesName[checkoutState]}=`;
  const cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return null;
};

//checkout page check if cookies is valid with the given userid
export const hasCookies = (id, checkoutState) => {
  try {
    let storedCookies = getCookie(checkoutState);
    if (storedCookies == id) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const createCookies = async (data, checkoutState) => {
  const userId = uuidv4();
  data.id = userId;
  const cartInfo = data;
  await setCookie(userId, checkoutState, cartInfo, "create");

  return userId;
};

//update the cookies with a new expiry time
export const updateCookies = async (data) => {
  let storedCookies = getCookie("checkout");
  //in case all items remove from cart
  if (!isNull(storedCookies)) {
    await setCookie(storedCookies, "checkout", data, "update");
  }
};

export const removeCookies = async (checkoutState, userID) => {
  document.cookie = `${cookiesName[checkoutState]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  // await deleteCart(userID);
};

export const isCookiesValid = (checkoutState) => {
  try {
    let storedCookies = getCookie(checkoutState);
    if (!isNull(storedCookies)) {
      return storedCookies;
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const getCartCookies = async (checkoutState) => {
  try {
    let userID = getCookie(checkoutState);
    // storedCookies = JSON.parse(storedCookies);
    if (userID) {
      // const re = isObject(storedCookies.cartInfo);
      // return re;
      const res = await getCart(userID);
      return res;
    }
  } catch (e) {
    console.error("getCartCookies error:", e);
    return {};
  }
  return {};
};
