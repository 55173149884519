import React, { useState } from "react";

const VariantsCheckbox = (props) => {
  const [value, setValue] = useState(props.init[0].name);

  const handleChange = () => {
    const newValue = value === "true" ? "false" : "true";
    setValue(newValue);
    props.setVariants(props.name, newValue);
  };

  return (
    <div
      id={`v-${props.name.replace(/\s+/g, "-")}`}
      className="flex items-center mt-4 block text-sm font-medium"
    >
      <input
        id={props.name}
        onChange={handleChange}
        checked={value === "true" ? true : false}
        type="checkbox"
        className="form-checkbox h-5 w-5"
      />
      <label htmlFor={props.name} className="ml-2">
        {props.name}
      </label>
    </div>
  );
};

export default VariantsCheckbox;
